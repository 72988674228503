import { createStore, combineReducers } from 'redux';

function replaceOrder(state, order, modifier) {
	const i = state.findIndex(({ _id }) => _id === order);
	if(i < 0) {
		console.warn('Unknown order was updated');
		return state;
	}
	const replacement = Object.assign({}, state[i]);
	modifier(replacement);
	return state.slice(0, i).concat(replacement, state.slice(i + 1));
}

function orders(state = [], action) {
	if(action.type === 'setOrders') {
		const mapping = {};
		action.orders.forEach(order => {
			mapping[order._id] = true;
		});
		const retain = state.filter(order => {
			return order.retain && !mapping[order._id];
		});
		return action.orders.concat(retain);
	} else if(action.type === 'DeletePickOrder') {
		return state.filter(({ _id }) => _id !== action.payload);
	} else if(action.type === 'NewPickOrder') {
		const index = state.findIndex(o => o._id === action.payload._id);
		if(index >= 0) {
			const copy = state.slice();
			copy[index] = action.payload;
			return copy;
		}
		return state.concat(action.payload);
	} else if(action.type === 'UpdatePickOrderLine') {
		const { order, line, lastModified, lastInformed } = action.payload;
		return replaceOrder(state, order, replacement => {
			if(lastModified) {
				replacement.lastModified = lastModified;
			}
			if(lastInformed) {
				replacement.lastInformed = lastInformed;
			}
			replacement.lines = replacement.lines.map(l => {
				if(l._id === line) {
					const updates = Object.assign({}, action.payload);
					delete updates.order;
					delete updates.line;
					delete updates.lastModified;
					delete updates.lastInformed;
					return Object.assign({}, l, updates);
				}
				return l;
			});
		});
	} else if(action.type === 'UpdatePickOrder') {
		return replaceOrder(state, action.payload._id, replacement => {
			Object.assign(replacement, action.payload);
		});
	} else if(action.type === 'findOrder') {
		return state.concat(action.orders.map(order => {
			order.retain = true;
			return order;
		}));
	} else if(action.type === 'UpdateMultipleOrders') {
		const ids = action.payload._id;
		const copy = { type: 'UpdatePickOrder', payload: Object.assign({}, action.payload) };
		return ids.reduce((s, id) => {
			copy.payload._id = id;
			return orders(s, copy);
		}, state);
	}
	return state;
}

function socketStatus(state = false, action) {
	if(action.type === 'setSocketStatus') {
		return action.connected;
	}
	return state;
}

function persons(state = [], action) {
	if(action.type === 'setPersons') {
		return action.persons;
	} else if(action.type === 'UpdatePerson') {
		const { payload } = action;
		const i = state.findIndex(({ _id }) => _id === payload._id);
		if(i < 0) {
			return state.concat(payload);
		}
		const replacement = Object.assign({}, state[i], payload);
		return state.slice(0, i).concat(replacement, state.slice(i + 1));
	} else if(action.type === 'DeletePerson') {
		return state.filter(({ _id }) => _id !== action.payload);
	}
	return state;
}

function person(state = null, action) {
	if(action.type === 'setPerson') {
		return action.person;
	}
	return state;
}

function locations(state = [], action) {
	if(action.type === 'setLocations') {
		return action.locations;
	}
	return state;
}

function freight(state = [], action) {
	if(action.type === 'setFreight') {
		return action.freight;
	}
	return state;
}

function pallets(state = [], action) {
	if(action.type === 'setPallets') {
		return action.pallets;
	}
	return state;
}

function terms(state = [], action) {
	if(action.type === 'setTerms') {
		return action.terms;
	}
	return state;
}

function cod(state = [], action) {
	if(action.type === 'setCOD') {
		return action.cod;
	}
	return state;
}

function delivery(state = [], action) {
	if(action.type === 'setDelivery') {
		return action.delivery;
	}
	return state;
}

function updateLocation(_id, items, state, add = false) {
	const i = state.findIndex(l => l._id === _id);
	if(i < 0) {
		return state.concat({ _id, items });
	}
	const replacementItems = {};
	state[i].items.forEach(line => {
		replacementItems[line.item] = line;
	});
	items.forEach(line => {
		replacementItems[line.item] = Object.assign({ item: line.item }, replacementItems[line.item]);
		if(add && replacementItems[line.item].quantity) {
			replacementItems[line.item].quantity += line.quantity;
		} else {
			replacementItems[line.item].quantity = line.quantity;
		}
	});
	const replacement = Object.assign({}, state[i], {
		items: Object.keys(replacementItems).map(item => {
			return replacementItems[item];
		})
	});
	const locs = state.slice();
	locs[i] = replacement;
	return locs;
}

function blockLocation(_id, blocked, state) {
	const i = state.findIndex(l => l._id === _id);
	if(i < 0) {
		return state.concat({ _id, items: [], blocked });
	}
	const locs = state.slice();
	locs[i] = Object.assign({}, state[i], { blocked });
	return locs;
}

function blockItems(_id, items, state) {
	const i = state.findIndex(l => l._id === _id);
	if(i < 0) {
		return state;
	}
	const locs = state.slice();
	locs[i] = Object.assign({}, state[i]);
	locs[i].items = locs[i].items.slice();
	items.forEach(line => {
		const index = locs[i].items.findIndex(l => l.item === line.item);
		if(index >= 0) {
			locs[i].items[index] = Object.assign({}, locs[i].items[index]);
			locs[i].items[index].blocked = line.blocked;
		}
	});
	return locs;
}

function stock(state = [], action) {
	if(action.type === 'setStock') {
		return action.stock;
	} else if(action.type === 'NewLocation') {
		const id = action.payload;
		if(!state.find(({ _id }) => _id === id)) {
			return state.concat({
				_id: id,
				items: []
			});
		}
	} else if(action.type === 'UpdateLocation') {
		const { _id, items, blocked, blockedItems } = action.payload;
		let out = state;
		if(items) {
			out = updateLocation(_id, items, out);
		}
		if(typeof blocked === 'boolean') {
			out = blockLocation(_id, blocked, out);
		}
		if(blockedItems) {
			out = blockItems(_id, blockedItems, out);
		}
		return out;
	} else if(action.type === 'AddContainer') {
		const { locations: locs } = action.payload;
		return Object.keys(locs).reduce((s, _id) => {
			const location = locs[_id];
			return updateLocation(_id, Object.keys(location).map(item => {
				return { item, quantity: location[item] };
			}), s, true);
		}, state);
	} else if(action.type === 'MoveItems') {
		const { items, location } = action.payload;
		return updateLocation(location, Object.keys(items).map(item => {
			return { item, quantity: items[item] };
		}), state, true);
	} else if(action.type === 'MassMutation') {
		const byId = {};
		state.forEach(loc => {
			byId[loc._id] = loc;
		});
		Object.keys(action.payload).forEach(location => {
			const items = action.payload[location];
			if(!byId[location]) {
				byId[location] = { _id: location, items: [] };
			} else {
				byId[location] = Object.assign({}, byId[location]);
				byId[location].items = byId[location].items.slice();
			}
			Object.keys(items).forEach(item => {
				const index = byId[location].items.findIndex(i => i.item === item);
				const quantity = items[item];
				if(index < 0) {
					byId[location].items.push({ item, quantity });
				} else {
					byId[location].items[index] = Object.assign({}, byId[location].items[index]);
					byId[location].items[index].quantity += quantity;
				}
			});
		});
		return Object.keys(byId).map(id => byId[id]);
	}
	return state;
}

function elstPickedUp(state = false, action) {
	if(action.type === 'ElstPickedUp') {
		return action.payload;
	}
	return state;
}

export const BATCH_TYPE = 'BATCH_UPDATE';

function batchReducer(reducer) {
	return (state, action) => {
		if(action.type === BATCH_TYPE) {
			return action.payload.reduce(reducer, state);
		}
		return reducer(state, action);
	};
}

export default createStore(batchReducer(combineReducers({
	orders,
	socketStatus,
	persons,
	person,
	locations,
	pallets,
	terms,
	cod,
	delivery,
	freight,
	stock,
	elstPickedUp
})));
