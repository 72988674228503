import React, { useState } from 'react';
import EasySwipe from 'react-easy-swipe';

const DEFAULT_STATE = {
	x: 0, y: 0
};

export default function Swipe({ children, onSwipe }) {
	const [state, setState] = useState(DEFAULT_STATE);

	return <EasySwipe
		onSwipeStart={() => setState(DEFAULT_STATE)}
		onSwipeMove={({ x, y }) => setState({ x, y })}
		onSwipeEnd={() => onSwipe(Object.assign({}, state))}>
		{children}
	</EasySwipe>;
}
