import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Switch from '../Switch';
import queryString from 'query-string';
import { sortLocations } from './LocationList';
import { splitLocation, stringifyLocation } from '../../helper';
import { getImage } from '../../api';
import { updateLocation, blockLocation } from '../../websocket';
import '../../css/locations.css';

class EditLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: '',
			items: props.location.items.slice().sort(sortLocations),
			redirect: false
		};
		this.submit = this.submit.bind(this);
		this.addItem = this.addItem.bind(this);
	}

	change(i) {
		return e => {
			const value = e.target.value.replace(/[^0-9]/g, '');
			const { items } = this.state;
			this.setState({
				items: items.slice(0, i).concat(Object.assign({}, items[i], {
					quantity: value === '' ? value : parseInt(value, 10)
				})).concat(items.slice(i + 1))
			});
		};
	}

	submit(e) {
		e.preventDefault();
		const { items } = this.props.location;
		const before = {};
		items.forEach(item => {
			before[item.item] = item.quantity;
		});
		const changes = this.state.items.filter(item => before[item.item] !== item.quantity
			&& (item.item in before || item.quantity !== 0));
		updateLocation(this.props.location._id, changes);
		this.setState({ redirect: true });
	}

	addItem(e) {
		e.preventDefault();
		const { items } = this.state;
		let { item } = this.state;
		item = item.trim();
		if(item) {
			this.setState({
				item: '',
				items: items.concat({
					item,
					quantity: 0
				})
			});
		}
	}

	render() {
		const { location, highlight } = this.props;
		const { items, item, redirect } = this.state;
		if(redirect) {
			return <Redirect to={`/stock/${location._id}`} />;
		}
		return <section className="container">
			<h1>{stringifyLocation(splitLocation(location._id))}</h1>
			<p>
				<Switch value={location.blocked || false} onChange={e => {
					blockLocation(location._id, e.target.checked);
				}} /> Geblokkeerd
			</p>
			<form onSubmit={this.submit}>
				<Table>
					<thead>
						<tr>
							<th colSpan={2}>Item</th>
							<th>Hoeveelheid</th>
						</tr>
					</thead>
					<tbody className="locations">
						{items.map((it, i) => <tr key={i} className={it.item.includes(highlight) ? 'highlight' : ''}>
							<td className="item"><img src={getImage(it.item)} alt="" /></td>
							<td>{it.item}</td>
							<td>
								<input
									onChange={this.change(i)}
									type="number"
									className="form-control"
									value={it.quantity} />
							</td>
						</tr>)}
						<tr>
							<td>
								<input
									type="text"
									placeholder="Item"
									value={item}
									onChange={e => this.setState({ item: e.target.value })}
									className="form-control" />
							</td>
							<td colSpan={2}>
								<button className="btn btn-primary" onClick={this.addItem} disabled={!item}>Voeg toe</button>
							</td>
						</tr>
					</tbody>
				</Table>
				<div className="form-group">
					<input type="submit" value="Save" className="btn btn-primary" />
				</div>
			</form>
		</section>;
	}
}

function Page(props) {
	const { stock, match } = props;
	const location = stock.find(({ _id }) => _id === match.params.id);
	if(!location) {
		return null;
	}
	const query = props.location.search ? queryString.parse(props.location.search) : {};
	return <EditLocation location={location} highlight={query.highlight} />;
}

export default connect(({ stock }) => {
	return { stock };
})(Page);
