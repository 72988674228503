import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import randomcolor from 'randomcolor';
import { Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';
import { deleteOrder, updateOrder, updateOrders } from '../../websocket';
import { format, trimDate } from '../../helper';
import '../../css/listorder.css';

const ROUNDING = 10;
const PERCENT = 100;

function getCountryColor(country) {
	if(country === 'GB') {
		return 'red';
	}
	return randomcolor({ seed: country });
}

class ListOrder extends Component {
	constructor(props) {
		super(props);
		this.delete = this.delete.bind(this);
		this.toTomorrow = this.toTomorrow.bind(this);
		this.noStickers = this.noStickers.bind(this);
	}

	delete(e) {
		e.preventDefault();
		const { _id, code } = this.props.order;
		if(window.confirm(`Weet u zeker dat u ${code} wilt verwijderen?`)) {
			deleteOrder(_id);
		}
	}

	selectPickers(abc) {
		return e => {
			const update = {
				pickers: [...e.target.options].filter(({ selected }) => selected).map(({ value }) => value),
				teamLeader: this.props.person._id
			};
			updateOrders(abc.map(o => o._id), update);
		};
	}

	toTomorrow(e) {
		e.preventDefault();
		const { _id, dateOverride, priority } = this.props.order;
		updateOrder(_id, {
			dateOverride: trimDate(moment(dateOverride).add(1, 'd'))
		});
		if(priority < 1) {
			updateOrder(_id, { priority: 1 });
		}
	}

	prio(delta) {
		const { _id, priority } = this.props.order;
		return e => {
			e.preventDefault();
			updateOrder(_id, {
				priority: priority + delta
			});
		};
	}

	update(abc, key) {
		return e => updateOrders(abc.map(o => o._id), key, e.target.value);
	}

	noStickers(e) {
		e.preventDefault();
		const { _id, noStickers } = this.props.order;
		updateOrder(_id, {
			noStickers: !noStickers
		});
	}

	render() {
		const { order, persons, person, locations, freight, first, highlight, openModal, abc } = this.props;
		const progress = {
			SUCCESS: 0,
			FAILED: 0,
			NONE: 0,
			UNKNOWN: 0
		};
		const prices = {};
		const cs = person.role === 'CUSTOMER_SERVICE';
		const totalItems = abc.map(o => o.lines).reduce((all, l) => all.concat(l), []).reduce((items, line) => {
			const { status, quantity, price } = line;
			const pickable = status !== 'NONE' || Object.keys(line.locations).length;
			Object.keys(price).forEach(currency => {
				if(!(currency in prices)) {
					prices[currency] = {
						known: 0,
						unknown: 0
					};
				}
				prices[currency][pickable ? 'known' : 'unknown'] += price[currency];
			});
			if(!pickable && !cs) {
				return items;
			}
			if(pickable) {
				progress[status] += quantity;
			} else {
				progress.UNKNOWN += quantity;
			}
			return items + quantity;
		}, 0);
		const classes = ['order'];
		if(order.code.startsWith('B2C')) {
			classes.push('b2c');
		}
		if(highlight) {
			classes.push('highlight');
		}
		if(order.noStickers) {
			classes.push('no-stickers');
		}
		if(first) {
			classes.push('first');
		}
		const teamLeader = persons.find(({ _id }) => _id === order.teamLeader);
		const pickers = persons.filter(({ _id }) => order.pickers.includes(_id));
		const packager = order.packager && persons.find(({ _id }) => _id === order.packager);
		const shipmentDate = new Date(order.shipmentDate);
		return <li className={classes.join(' ')}>
			<Link to={`/order/${order._id}`} className="link-tile" id={order._id}>
				<div className="row">
					<h4 className={abc.length > 1 ? 'col-sm-12' : 'col-sm-6'}>
						{order.dropship && person.role !== 'PACKAGER' && !cs ? 'Dropship order' : abc.map(({ code }) => code).join(' ')}
						{order.pickers.length > 1 && ' (multi)'}
						{!!order.interesting && <>{' '}<i className="fas fa-bullhorn" title="Aandacht vereist"></i></>}
					</h4>
					{cs ? <div className="col-sm-6 text-right">
						<ButtonGroup title="Prioriteit">
							<Button size="sm" onClick={this.prio(-1)}><i className="fas fa-arrow-down"></i></Button>
							<Button size="sm" disabled>{order.priority}</Button>
							<Button size="sm" onClick={this.prio(1)}><i className="fas fa-arrow-up"></i></Button>
						</ButtonGroup>
						<Button color="default" size="sm" title="Zelf ophalen" onClick={this.noStickers}>
							<input type="checkbox" checked={order.noStickers} onChange={() => null} />
						</Button>
						<Button color="primary" size="sm" onClick={openModal} title="Datum"><i className="fas fa-calendar-alt"></i></Button>
						<Button color="primary" size="sm" onClick={this.toTomorrow} title="Morgen"><i className="fas fa-arrow-right"></i></Button>
						{order.status === 'NONE' && <Button color="danger" size="sm" onClick={this.delete} title="Delete"><i className="fas fa-times"></i></Button>}
					</div> : order.dropship && person.role === 'PACKAGER' && <div className="col-sm-6 text-right">{order.customer.code} {order.customer.name}</div>}
				</div>
				<div className="row">
					<div className={cs ? 'col-md-4' : 'col-md-5'}>
						{(!order.dropship || person.role === 'PACKAGER') && `${order.customer.code} ${order.customer.name}`}
					</div>
					<div className="col-md-3 text-center">
						{totalItems} items
						{Object.keys(prices).map(currency => {
							const { known, unknown } = prices[currency];
							return <Fragment key={currency}>
								, {format(currency, known)}
								{unknown > 0 && <span className="color-unknown"> + {format(currency, unknown)}</span>}
							</Fragment>;
						})}
					</div>
					{cs && <div className="col-md-2">
						{order.campaign || ''}
					</div>}
					<div className={cs ? 'col-md-3 text-right' : 'col-md-4 text-right'}>
						{`${shipmentDate.toLocaleString()} `}
						<span className="country" style={{ backgroundColor: getCountryColor(order.address.country) }}>{order.address.country}</span>
					</div>
				</div>
				{cs && <div className="row">
					<div className="col-md-3">
						Locatie: <strong style={{ color: randomcolor({ seed: `${order.location}` })}}>{order.location || '--'}</strong>
					</div>
					<div className="col-md-3">
						Vrachtlocatie: <strong style={{ color: randomcolor({ seed: `${order.freight}` })}}>{order.freight || '--'}</strong>
					</div>
					<div className="col-md-3">
						Teamleider: {teamLeader ? teamLeader.name : '--'}
					</div>
					<div className="col-md-3">
						Orderpicker: {pickers.length > 0 ? pickers.map(({ name }) => name).join(', ') : '--'}
						{!!packager && <>{' '}Inpakker: {packager.name}</>}
					</div>
				</div>}
			</Link>
			{person.role === 'TEAM_LEADER' && <div className="row">
				<div className="col-md-4">
					{['NONE', 'ON_PALLET'].includes(order.status) ? <select
						className="form-control" value={order.location || ''}
						onChange={this.update(abc, 'location')}>
						<option disabled value="">Locatie</option>
						{locations.map(l =>
							<option key={l}>{l}</option>
						)}
					</select> : !!packager && <>Inpakker: {packager.name}</>}
				</div>
				<div className="col-md-4">
					{['SEALING', 'ON_PALLET'].includes(order.status) && <select
						className="form-control" value={order.freight || ''}
						onChange={this.update(abc, 'freight')}>
						<option disabled value="">Vrachtlocatie</option>
						{freight.map(l =>
							<option key={l}>{l}</option>
						)}
					</select>}
				</div>
				<div className="col-md-4">
					{order.status !== 'DONE' && <select
						className="form-control" value={order.dropship ? order.pickers : order.pickers[0] || ''}
						multiple={order.dropship}
						onChange={this.selectPickers(abc)}>
						<option disabled value="">Orderpicker</option>
						{persons.filter(p => p.roles.includes('PICKER')).map(p =>
							<option key={p._id} value={p._id}>{p.name}</option>
						)}
					</select>}
				</div>
			</div>}
			<div className="order-progress">
				<span className="percentage">
					{Math.round((progress.SUCCESS + progress.FAILED) / totalItems * ROUNDING * PERCENT) / ROUNDING}% klaar
				</span>
				{Object.keys(progress).map(key => {
					const percentage = `${Math.round(progress[key] / totalItems * ROUNDING * PERCENT) / ROUNDING}%`;
					return <div key={key} title={percentage} className={key.toLowerCase()} style={{ width: percentage }}></div>;
				})}
			</div>
		</li>;
	}
}

export default connect(({ persons, person, locations, freight }) => {
	return { persons, person, locations, freight };
})(ListOrder);
