import React, { Component, Fragment } from 'react';
import { Table, Button, Row, Col } from 'reactstrap';
import Address from './Address';
import AbcOrder from './AbcOrder';
import PTMDAddress from '../PTMDAddress';
import { format } from '../../helper';
import { getAddress } from '../../api';
import '../../css/slip.css';

const HAS_PRICES = ['NL', 'AT', 'DE', 'BE'];

class PackingSlip extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.componentDidUpdate({});
	}

	componentDidUpdate(props) {
		if(this.props.abc !== props.abc || this.props.order !== props.order) {
			const update = {};
			this.props.abc.forEach(order => {
				if(order.dropship && !(order.customer.code in this.state) && !(order.customer.code in update)) {
					update[order.customer.code] = null;
					getAddress(order.customer.code).then(response => {
						this.setState({
							[order.customer.code]: response.address
						});
					}).catch(err => {
						this.setState(state => {
							const clone = Object.assign({}, state);
							delete clone[order.customer.code];
							return clone;
						});
						console.warn(`Failed to get address for ${order.customer.code}`, err);
					});
				}
			});
		}
	}

	render() {
		const { abc } = this.props;
		const { dropship } = this.props.order;
		const hasPrices = !dropship && HAS_PRICES.includes(this.props.order.address.country);
		return <section className="container packing-slip">
			{abc.map((order, i) => {
				const items = {};
				order.lines.forEach(line => {
					let quantity = 0;
					if(line.status === 'SUCCESS') {
						quantity = line.quantity;
					} else if(line.status === 'FAILED' && line.picked) {
						quantity = line.picked;
					}
					if(quantity > 0) {
						if(!items[line.item.code]) {
							items[line.item.code] = {
								item: line.item,
								quantity: 0
							};
						}
						items[line.item.code].quantity += quantity;
					}
				});
				const address = this.state[order.customer.code];
				return <Fragment key={order._id}>
					<h1 className={dropship && i ? 'page-break-before' : ''}>{order.code} {i === 0 && <Button title="Print" color="primary" onClick={() => window.print()}>
						<i className="fas fa-print"></i>
					</Button>}</h1>
					{order.reference && <p>Your reference: {order.reference}</p>}
					{(i === 0 || dropship) && <Row>
						<Col xs={6}><Address order={order} /></Col>
						<Col xs={6}>{address ? <Address order={{ address }} /> : <PTMDAddress />}</Col>
					</Row>}
					<Table>
						<thead>
							<tr>
								<th colSpan={4}>Item</th>
								<th className="text-right">Quantity</th>
								{hasPrices && <th className="text-right">Consumer price</th>}
							</tr>
						</thead>
						<tbody>
							{Object.keys(items).sort().map(code => <tr key={code}>
								<td><img src={items[code].item.image} alt="" /></td>
								<td>{code}</td>
								<td>{items[code].item.ean || ''}</td>
								<td>{items[code].item.description}</td>
								<td className="text-right">{items[code].quantity}</td>
								{hasPrices && <td className="text-right">{format('EUR', items[code].item.consumerPrice || '--')}</td>}
							</tr>)}
						</tbody>
					</Table>
				</Fragment>;
			})}
		</section>;
	}
}

export default function Page(props) {
	return <AbcOrder component={PackingSlip} {...props} />;
}
