import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, FormGroup, Input, Table } from 'reactstrap';
import { getImage, getInterestingItems, removeInterestingItem, addInterestingItem } from '../../api';

export default function InterestingItems() {
	const [items, setItems] = useState(null);
	const [error, setError] = useState(null);
	const [sku, setSku] = useState('');
	const [submitting, setSubmitting] = useState(false);
	useEffect(() => {
		getInterestingItems().then(setItems).catch(setError);
	}, []);
	function deleteItem(item) {
		setSubmitting(true);
		removeInterestingItem(item).then(() => {
			setItems(prev => prev.filter(i => i !== item));
			setSubmitting(false);
		}).catch(err => {
			setSubmitting(false);
			setError(err);
		});
	}
	function addItem(e) {
		e.preventDefault();
		const item = sku.trim();
		if(!item) {
			return setSku(item);
		}
		setSubmitting(true);
		addInterestingItem(item).then(() => {
			setItems(prev => prev.concat(item));
			setSku('');
			setSubmitting(false);
		}).catch(err => {
			setSubmitting(false);
			setError(err);
		});
	}
	return <section className="container">
		<h1>Items waarbij aandacht nodig is</h1>
		{error && <Alert color="danger">{error.message}</Alert>}
		{items && <Table>
			<thead>
				<tr>
					<th colSpan="2">Item</th>
					<th className="text-right">Verwijderen</th>
				</tr>
			</thead>
			<tbody>
				{items.map(item => {
					return <tr key={item}>
						<td className="item"><img src={getImage(item, 'small')} alt="" /></td>
						<td>{item}</td>
						<td className="text-right">
							<Button color="danger" size="sm" onClick={() => deleteItem(item)} disabled={submitting}><i className="fas fa-times"></i></Button>
						</td>
					</tr>;
				})}
			</tbody>
		</Table>}
		<Form onSubmit={addItem}>
			<FormGroup>
				<Input value={sku} onChange={e => setSku(e.target.value)} placeholder="SKU" type="text" />
			</FormGroup>
			<FormGroup>
				<Input type="submit" value="Toevoegen" className="btn btn-success" disabled={submitting || !sku.length} />
			</FormGroup>
		</Form>
	</section>;
}
