import React, { useState } from 'react';
import { Button } from 'reactstrap';
import AbcOrder from './AbcOrder';
import DachserStickers from '../edi/DachserStickers';
import DeliveryForm from '../edi/DeliveryForm';

function PackingStickers({ order, abc }) {
	const [data, setData] = useState(null);
	return <section className="sticker-page">
		<DeliveryForm
			className="container"
			order={order}
			abc={abc}
			post={submitting => <>
				<input type="submit" className="btn btn-primary" value="Genereer" disabled={submitting} />
				{data && <>
					<hr />
					<Button title="Print" color="primary" onClick={() => window.print()}>
						<i className="fas fa-print"></i>
					</Button>
				</>}
			</>}
			onData={setData}>
			<h1>{order.code}</h1>
		</DeliveryForm>
		{data && <DachserStickers data={data} />}
	</section>;
}

export default function Page(props) {
	return <AbcOrder component={PackingStickers} {...props} />;
}
