import React, { Fragment } from 'react';

function toNum(value) {
	if(!isNaN(value)) {
		return +value;
	}
	return value;
}

export default function OrderPallets({ pallets, readonly, abc, colli, setPallet, setColli, dropship, byPallet }) {
	function set(index, key) {
		return e => setColli(c => {
			const copy = c.slice();
			copy[index] = Object.assign({}, c[index], { [key]: toNum(e.target.value) });
			return copy;
		});
	}
	const maxPallets = abc.reduce((all, { maxPallets: max }) => Math.max(all, max), 0);
	const className = maxPallets && colli.length > maxPallets ? 'form-control is-invalid' : 'form-control';
	return <div>
		{dropship ? <h3>Colli</h3> : <h3>
			Pallets
			{maxPallets > 0 && <small className="text-danger"> (Max. {maxPallets})</small>}
		</h3>}
		<dl className="row">
			{!readonly && pallets.map(pallet => <Fragment key={pallet.id}>
				<dt className="col-sm-4">{pallet.name}</dt>
				<dd className="col-sm-8">
					<input
						className={className}
						type="number"
						min={0}
						onChange={e => setPallet(pallet.id, toNum(e.target.value || 0))}
						value={byPallet[pallet.id] ? byPallet[pallet.id].length : ''} />
				</dd>
				{byPallet[pallet.id] && !dropship && <Fragment>
					<dt className="col-sm-6">Height (cm)</dt>
					<dd className="col-sm-6">Weight (kg)</dd>
					{byPallet[pallet.id].map(p => {
						return <Fragment key={p.i}>
							<dt className="col-sm-6">
								<input
									className={className}
									type="number"
									min={0}
									onChange={set(p.i, 'height')}
									value={p.colli.height || ''} />
							</dt>
							<dd className="col-sm-6">
								<input
									className={className}
									type="number"
									min={0}
									onChange={set(p.i, 'weight')}
									value={p.colli.weight || ''} />
							</dd>
						</Fragment>;
					})}
				</Fragment>}
			</Fragment>)}
			{readonly && pallets.filter(pallet => byPallet[pallet.id]).map(pallet => <Fragment key={pallet.id}>
				<dt className="col-sm-4">{pallet.name}</dt>
				<dd className="col-sm-8">{byPallet[pallet.id].length}</dd>
			</Fragment>)}
		</dl>
	</div>;
}
