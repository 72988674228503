import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLoginUrl, version, checkLoggedIn } from '../api';
const TIMEOUT = 500;

const ROLES = {
	'Warehouse:CustomerService': 'Customer Service',
	'Warehouse:TeamLeader': 'Teamleider',
	'Warehouse:Picker': 'Orderpicker',
	'Warehouse:Management': 'Loodsbeheerder',
	'Warehouse:Packager': 'Inpakker',
	'Warehouse:MoveItems': 'Verplaatser'
};

class LoggedOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loginApi: null
		};
	}

	componentDidMount() {
		this.checkLoggedIn();
	}

	componentDidUpdate(props) {
		if(!this.props.person && props.person && !this.state.loginApi) {
			this.checkLoggedIn();
		}
	}

	checkLoggedIn() {
		checkLoggedIn().then(res => {
			if(typeof res === 'string') {
				this.setState({ loginApi: res });
			} else if(!res) {
				setTimeout(this.checkLoggedIn.bind(this), TIMEOUT);
			}
		});
	}

	login(role) {
		return e => {
			e.preventDefault();
			const { loginApi } = this.state;
			window.location = `${loginApi}?login=${encodeURIComponent(getLoginUrl(role))}`;
		};
	}

	render() {
		const { loginApi } = this.state;
		const { person } = this.props;
		if(person) {
			return null;
		} else if(!loginApi) {
			return <p>Loading</p>;
		}
		return <section className="container">
			<div className="row">
				<div className="col-6">
					<h1>Login als</h1>
				</div>
				<div className="col-6 text-right">
					<iframe src={`${loginApi}?type=logoutButton`}></iframe>
				</div>
			</div>
			<form onSubmit={this.login} className="text-center">
				{Object.keys(ROLES).map(role => <button key={role} className="btn btn-lg btn-primary m-3" onClick={this.login(role)}>{ROLES[role]}</button>)}
			</form>
			<div className="float-right">
				<small>v{version}</small>
			</div>
		</section>;
	}
}

export default connect(({ person }) => {
	return { person };
})(LoggedOut);
