import React from 'react';
import { connect } from 'react-redux';

function Status({ connected, person }) {
	if(connected || !person) {
		return null;
	}
	return <div id="connection-status">
		<p className="text-danger">U bent niet verbonden</p>
	</div>;
}

export default connect(({ socketStatus, person }) => {
	return { connected: socketStatus, person };
})(Status);
