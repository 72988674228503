import React, { Component } from 'react';
import { Alert, Table, Input } from 'reactstrap';
import Autocomplete from '../Autocomplete';
import { connect } from 'react-redux';
import { sortLocations } from './LocationList';

const MAX_RESULTS = 10;

function identity(v) {
	return v;
}

function getId(location) {
	return location._id;
}

class ItemForm extends Component {
	updateLine(index, cb) {
		this.props.setLines(lines => {
			const copy = lines.slice();
			copy[index] = Object.assign({}, lines[index]);
			cb(copy[index]);
			return copy;
		});
	}

	changeItem(index) {
		return (e, value) => {
			const search = value.trim();
			let matches = [];
			if(search) {
				matches = this.props.items.filter(item => item.includes(search)).slice(0, MAX_RESULTS);
			}
			this.updateLine(index, line => {
				line.item = value;
				line.itemMatches = matches;
				line.locations = undefined;
			});
		};
	}

	selectItem(index) {
		return (value, item) => {
			const locations = [];
			this.props.stock.forEach(location => {
				if(location.items.some(line => line.item === item)) {
					locations.push(location);
				}
			});
			this.updateLine(index, line => {
				line.item = item;
				line.locations = locations;
			});
		};
	}

	changeLine(index, field, searchLocs) {
		return (e, value) => {
			if(e.target) {
				value = e.target.value;
			} else if(typeof value !== 'string') {
				value = value._id;
			}
			let matches;
			if(searchLocs && field === 'location') {
				const search = value.trim().toUpperCase();
				if(search) {
					const split = search.split(/\s+/);
					matches = this.props.stock.filter(({ _id }) =>
						split.every(term => _id.includes(term))).slice(0, MAX_RESULTS);
				}
			}
			this.updateLine(index, line => {
				line[field] = value;
				if(matches) {
					line.locations = matches;
				}
			});
		};
	}

	render() {
		const { lines, error, save, useItemLocations } = this.props;
		let hasLines = false;
		return <section className="container">
			<Table>
				<thead>
					<tr>
						<th>Item</th>
						<th>Locatie</th>
						<th>Hoeveelheid</th>
					</tr>
				</thead>
				<tbody>
					{lines.concat({}).map((line, index) => {
						let max;
						if(useItemLocations) {
							max = 0;
						}
						if(line.locations) {
							const location = line.locations.find(loc => loc._id === line.location);
							if(location) {
								if(useItemLocations) {
									if(location.items) {
										for(const item of location.items) {
											if(item.item === line.item) {
												max = item.quantity;
												if(line.quantity > 0) {
													hasLines = true;
												}
												break;
											}
										}
									}
								} else if(line.quantity > 0) {
									hasLines = true;
								}
							}
						}
						return <tr key={index}>
							<td>
								<Autocomplete
									getItemValue={identity}
									onChange={this.changeItem(index)}
									onSelect={this.selectItem(index)}
									items={line.itemMatches || []}
									value={line.item || ''} />
							</td>
							<td>
								{useItemLocations ? <select className="form-control" value={line.location || ''} disabled={!line.locations} onChange={this.changeLine(index, 'location')}>
									<option value=""></option>
									{line.locations ? line.locations.map(location => {
										return <option key={location._id} value={location._id}>{location._id}</option>;
									}) : null}
								</select> : <Autocomplete
									getItemValue={getId}
									getItemKey={getId}
									onChange={this.changeLine(index, 'location', true)}
									onSelect={this.changeLine(index, 'location')}
									items={line.locations || []}
									value={line.location || ''} />}
							</td>
							<td>
								<Input type="number" min={0} max={max} value={line.quantity || ''} onChange={this.changeLine(index, 'quantity')} />
							</td>
						</tr>;
					})}
				</tbody>
			</Table>
			{error && <Alert color="danger">{error}</Alert>}
			<p><button className="btn btn-primary" disabled={!hasLines} onClick={save}>Save</button></p>
		</section>;
	}
}

export default connect(({ stock }) => {
	const items = {};
	stock.forEach(location => {
		location.items.forEach(line => {
			items[line.item] = true;
		});
	});
	return {
		stock: stock.slice().sort(sortLocations),
		items: Object.keys(items).sort()
	};
})(ItemForm);
