import React, { Component, Fragment } from 'react';
import { Alert, Table, UncontrolledCollapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getContainers } from '../../api';

export default class ContainerPlanning extends Component {
	constructor(props) {
		super(props);
		this.state = {
			weeks: null,
			error: null
		};
	}

	componentDidMount() {
		getContainers().then(containers => {
			const byWeek = {};
			containers.forEach(container => {
				container.eta = moment(container.eta);
				const key = container.eta.format('YYYY-WW');
				if(!byWeek[key]) {
					byWeek[key] = [];
				}
				byWeek[key].push(container);
			});
			this.setState({ weeks: Object.keys(byWeek).sort().map(week => {
				const days = [[], [], [], [], [], [], []];
				byWeek[week].forEach(container => {
					container.eta = container.eta.toDate();
					days[container.eta.getDay()].push(container.code);
				});
				return { days, week };
			}) });
		}).catch(err => {
			this.setState({ error: err.message });
		});
	}

	render() {
		const { error, weeks } = this.state;
		return <section className="container">
			{error && <Alert color="danger">{error}</Alert>}
			{weeks && <Table>
				<thead>
					<tr>
						<th></th>
						<th>Zo</th>
						<th>Ma</th>
						<th>Di</th>
						<th>Wo</th>
						<th>Do</th>
						<th>Vr</th>
						<th>Za</th>
					</tr>
				</thead>
				<tbody>
					{weeks.map(week => {
						return <tr key={week.week}>
							<th>{week.week}</th>
							{week.days.map((containers, day) => {
								const id = `w${week.week}-${day}`;
								return <td key={day}>
									<a href="#" id={id}>{containers.length || ''}</a>
									<UncontrolledCollapse toggler={`${id}`}>
										{containers.map((container, i) => <Fragment key={i}>
											<Link to={`/stock/container?container=${encodeURIComponent(container)}`}>{container}</Link><br />
										</Fragment>)}
									</UncontrolledCollapse>
								</td>;
							})}
						</tr>;
					})}
				</tbody>
			</Table>}
		</section>;
	}
}
