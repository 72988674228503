import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { exportLocationMutations, exportMutations, exportFailed, exportProductivity, exportInvoiced, exportCreated, exportDeleted, exportOrders } from '../../api';

const DAYS = 60;

export default function Reports({ date, cs }) {
	const [mutationsOpen, setMutationsOpen] = useState(false);
	const [exportsOpen, setExportsOpen] = useState(false);

	return <>
		{cs ? <>
			<ButtonDropdown isOpen={exportsOpen} toggle={() => setExportsOpen(!exportsOpen)}>
				<DropdownToggle color="primary" caret>Export</DropdownToggle>
				<DropdownMenu>
					<DropdownItem className="btn" onClick={() => {
						exportFailed(date);
					}}>Rood</DropdownItem>
					<DropdownItem className="btn" onClick={() => {
						exportProductivity(date);
					}}>Productiviteit</DropdownItem>
					<DropdownItem className="btn" onClick={() => {
						exportInvoiced(DAYS);
					}}>Facturatie</DropdownItem>
					<DropdownItem className="btn" onClick={() => {
						exportCreated(DAYS);
					}}>Aangemaakt</DropdownItem>
					<DropdownItem className="btn" onClick={() => {
						exportDeleted(DAYS);
					}}>Verwijderd</DropdownItem>
					<DropdownItem className="btn" onClick={() => {
						exportOrders(date);
					}}>Werkvoorraad</DropdownItem>
				</DropdownMenu>
			</ButtonDropdown>
			{' '}
		</> : null}
		<ButtonDropdown isOpen={mutationsOpen} toggle={() => setMutationsOpen(!mutationsOpen)}>
			<DropdownToggle color="primary" caret>Mutaties</DropdownToggle>
			<DropdownMenu>
				<DropdownItem className="btn" onClick={() => {
					exportLocationMutations(date);
				}}>Mutaties</DropdownItem>
				<DropdownItem className="btn" onClick={() => {
					exportMutations(date, 'container');
				}}>Opboekingen</DropdownItem>
				<DropdownItem className="btn" onClick={() => {
					exportMutations(date, 'order');
				}}>Gepakt</DropdownItem>
				<DropdownItem className="btn" onClick={() => {
					exportMutations(date, 'move');
				}}>Verplaatsingen</DropdownItem>
				<DropdownItem className="btn" onClick={() => {
					exportMutations(date, 'all');
				}}>Alles</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	</>;
}
