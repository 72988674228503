import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import OrdersTab from './OrdersTab';
import { inPallets, getSize } from '../../dimensions';
import '../../css/orderlist.css';

function sortModified(lhs, rhs) {
	if(lhs.lastModified > rhs.lastModified) {
		return -1;
	} else if(lhs.lastModified < rhs.lastModified) {
		return 1;
	}
	return 0;
}

function sortModifiedReverse(lhs, rhs) {
	return -sortModified(lhs, rhs);
}

function renderTab(cat, categories, { highlight, role }) {
	return <OrdersTab cat={cat} highlight={highlight} role={role} />;
}

function renderStatsTab(cat, categories, options) {
	const assigned = {};
	const locationsInUse = {};
	const names = {};
	options.persons.forEach(person => {
		if(person.roles.includes('PICKER')) {
			assigned[person._id] = 0;
			names[person._id] = person.name;
		}
	});
	categories.assigned.orders.forEach(order => {
		if(order.location) {
			locationsInUse[order.location] = true;
		}
		order.pickers.forEach(person => {
			if(!(person in assigned)) {
				assigned[person] = 0;
			}
			assigned[person]++;
		});
	});
	const { locations } = options;
	if(locations.length) {
		categories.planned.orders.concat(categories.pallet.orders).forEach(order => {
			if(order.location) {
				locationsInUse[order.location] = true;
			}
		});
	}
	return <Row>
		<Col md={8} lg={10}>
			{renderTab(cat, categories, options)}
		</Col>
		<Col md={4} lg={2}>
			{locations.length > 0 && <Fragment>
				<h4>Locaties</h4>
				<Row className="locations">
					{locations.map(location => <Col key={location} xs={4} className={locationsInUse[location] ? 'bg-danger' : 'bg-success'}>
						{location}
					</Col>)}
				</Row>
			</Fragment>}
			<h4>Pickers</h4>
			<dl className="row">
				{Object.keys(assigned).sort((lhs, rhs) => assigned[rhs] - assigned[lhs]).map(id => <Fragment key={id}>
					<dt className="col-8 picker-name">{names[id]}</dt>
					<dd className="col-4">{assigned[id]}</dd>
				</Fragment>)}
			</dl>
		</Col>
	</Row>;
}

function renderFreightTab(cat, categories, options) {
	const palletsPlaces = {};
	const byId = {};
	const PLACE = 120;
	const ROUNDING = 2;
	options.pallets.forEach(pallet => {
		byId[pallet.id] = Math.round(ROUNDING * pallet.length / PLACE) / ROUNDING;
	});
	categories.sealed.orders.forEach(order => {
		if(order.freight) {
			const p = order.pallets.reduce((total, colli) => total + byId[colli.pallet], 0);
			if(p > 0) {
				if(order.freight in palletsPlaces) {
					palletsPlaces[order.freight] += p;
				} else {
					palletsPlaces[order.freight] = p;
				}
			}
		}
	});
	return <Row>
		<Col md={8} lg={10}>
			{renderTab(cat, categories, options)}
		</Col>
		<Col md={4} lg={2}>
			<h4>Vrachtlocaties</h4>
			<dl className="row">
				{Object.keys(palletsPlaces).sort().map(location => <Fragment key={location}>
					<dt className="col-6 picker-name">{location}</dt>
					<dd className="col-6">{palletsPlaces[location]}</dd>
				</Fragment>)}
			</dl>
		</Col>
	</Row>;
}

export function getTab(order) {
	if(order.status === 'DONE') {
		return 'done';
	} else if(order.status === 'SEALING') {
		return 'sealed';
	} else if(order.status === 'ON_PALLET') {
		return 'pallet';
	} else if(order.pickers.length) {
		return 'assigned';
	}
	return 'planned';
}

function getCategories({ orders, role }) {
	const picker = role === 'PICKER';
	const packager = role === 'PACKAGER';
	const categories = {
		planned: {
			orders: [],
			visible: !picker && !packager,
			name: 'Werkvoorraad',
			sort: false,
			filterB2C: true,
			render: role === 'TEAM_LEADER' ? renderStatsTab : renderTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		},
		assigned: {
			orders: [],
			visible: !packager,
			name: 'Te lopen',
			sort: sortModifiedReverse,
			render: picker ? renderTab : renderStatsTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		},
		pallet: {
			orders: [],
			visible: true,
			name: 'Gelopen',
			sort: sortModifiedReverse,
			render: renderTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		},
		sealed: {
			orders: [],
			visible: !picker,
			name: 'Sealed',
			sort: sortModified,
			render: packager ? renderTab : renderFreightTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		},
		done: {
			orders: [],
			visible: !picker && !packager,
			name: 'Opgehaald',
			sort: sortModified,
			render: renderTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		},
		error: {
			orders: [],
			visible: role === 'CUSTOMER_SERVICE',
			name: 'Probleem',
			sort: sortModified,
			render: renderTab,
			size: 0, price: 0, pallets: 0, unknownPrice: 0
		}
	};
	orders.forEach(order => {
		const cat = categories[getTab(order)];
		if(cat === categories.planned || cat === categories.assigned || !order.dropship || role !== 'TEAM_LEADER') {
			cat.orders.push(order);
		} else {
			return;
		}
		const hasErrors = order.lines.some(({ status }) => status === 'FAILED');
		if(hasErrors) {
			categories.error.orders.push(order);
		}
		const orderSize = order.lines.reduce((sub, line) => {
			if(line.price.EUR) {
				const pickable = line.status !== 'NONE' || Object.keys(line.locations).length;
				if(pickable) {
					cat.price += line.price.EUR;
				} else {
					cat.unknownPrice += line.price.EUR;
				}
				if(hasErrors) {
					categories.error.price += line.price.EUR;
				}
			}
			return sub + getSize(line);
		}, 0);
		const pallets = inPallets(orderSize);
		cat.size += orderSize;
		cat.pallets += pallets;
		if(hasErrors) {
			categories.error.size += orderSize;
			categories.error.pallets += pallets;
		}
	});
	for(const key in categories) {
		const cat = categories[key];
		if(cat.sort) {
			cat.orders.sort(cat.sort);
		}
	}
	return categories;
}

function OrderList(props) {
	const [categories, setCategories] = useState(() => getCategories(props));
	useEffect(() => {
		setCategories(getCategories(props));
	}, [props.orders]);
	const { orders, history, date, persons, locations, pallets, highlight, active, role } = props;
	if(!active) {
		return null;
	}
	let { tab } = props;
	if(!(tab in categories)) {
		for(const key in categories) {
			if(categories[key].visible) {
				tab = key;
				break;
			}
		}
	}
	const options = { persons, locations, pallets, highlight, role };
	return <section id="orders">
		{orders.length ? <Fragment>
			<Nav tabs>
				{Object.keys(categories).filter(key => categories[key].visible).map(key => <NavItem key={key}>
					<NavLink
						active={tab === key}
						onClick={() => history.push(`/${date}/${key}`)}>
						{categories[key].name}
					</NavLink>
				</NavItem>)}
			</Nav>
			<TabContent activeTab={tab}>
				{Object.keys(categories).map(key => {
					const cat = categories[key];
					return <TabPane tabId={key} key={key}>
						{tab === key && cat.render(cat, categories, options)}
					</TabPane>;
				})}
			</TabContent>
		</Fragment> : <p>Geen orders voor deze dag</p>}
	</section>;
}

export default connect(({ persons, locations, pallets }) => {
	return { persons, locations, pallets };
})(OrderList);
