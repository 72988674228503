import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import LocationList from './LocationList';
import NewLocation from './NewLocation';
import EditLocation from './EditLocation';
import LocationPage from './LocationPage';
import Container from './Container';
import MoveMultiple from './MoveMultiple';
import ContainerPlanning from './ContainerPlanning';
import BreakPage from './BreakPage';
import MovingPage from './MovingPage';
import ReturnPage from './ReturnPage';
import InterestingItems from './InterestingItems';
import { getStock, getPersons } from '../../api';
import { useWebSocket } from '../../websocket';
import '../../css/stock.css';

export default function ManagementApp({ person }) {
	useWebSocket(getStock);
	useEffect(() => {
		if(person.roles.includes('EDIT_LOCATIONS')) {
			getPersons();
		}
	}, person.roles);
	const canEdit = person.roles.includes('EDIT_LOCATIONS');
	const canMove = person.roles.includes('MOVE_ITEMS');
	return <Switch>
		{canEdit && <Route path="/moving" component={MovingPage} />}
		{canEdit && <Route path="/stock/edit/:id" component={EditLocation} />}
		<Route path="/stock/container" component={Container} />
		{canEdit && <Route path="/stock/new" component={NewLocation} />}
		{canMove && <Route path="/stock/move" component={MoveMultiple} />}
		<Route path="/stock/containers" component={ContainerPlanning} />
		{canEdit && <Route path="/stock/break" component={BreakPage} />}
		{canEdit && <Route path="/stock/return" component={ReturnPage} />}
		{canEdit && <Route path="/stock/items" component={InterestingItems} />}
		<Route path="/stock/:id" component={LocationPage} />
		<Route component={LocationList} />
	</Switch>;
}
