import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import 'react-datepicker/dist/react-datepicker.css';
import OrderForm from './OrderForm';
import OrderList from './OrderList';
import OrderSearch from './OrderSearch';
import { trimDate } from '../../helper';

export function compareOrders(lhs, rhs) {
	const d = rhs.priority - lhs.priority;
	if(d) {
		return d;
	} else if(lhs.shipmentDate > rhs.shipmentDate) {
		return 1;
	} else if(lhs.shipmentDate < rhs.shipmentDate) {
		return -1;
	} else if(lhs.customer.code > rhs.customer.code) {
		return 1;
	} else if(lhs.customer.code < rhs.customer.code) {
		return -1;
	} else if(lhs.code > rhs.code) {
		return 1;
	} else if(lhs.code < rhs.code) {
		return -1;
	}
	return 0;
}

function sortOrders(orders, mergeDropship) {
	let swiss;
	const groupPrio = {};
	orders.forEach(order => {
		if(order.address.country === 'CH' && (!swiss || compareOrders(swiss, order) > 0)) {
			swiss = order;
		}
		const { code } = order.customer;
		if(!(code in groupPrio) || compareOrders(groupPrio[code], order) > 0) {
			groupPrio[code] = order;
		}
	});
	return orders.sort((lhs, rhs) => {
		let lo = lhs;
		let ro = rhs;
		if(!lhs.dropship) {
			if(lhs.address.country === 'CH') {
				lo = swiss;
			}
			if(lo !== swiss) {
				lo = groupPrio[lhs.customer.code];
			}
		}
		if(!rhs.dropship) {
			if(rhs.address.country === 'CH') {
				ro = swiss;
			}
			if(ro !== swiss) {
				ro = groupPrio[rhs.customer.code];
			}
		}
		if(lo === ro) {
			lo = lhs;
			ro = rhs;
		}
		if(mergeDropship) {
			if(lo.dropship && !ro.dropship) {
				return -1;
			} else if(ro.dropship && !lo.dropship) {
				return 1;
			}
		}
		return compareOrders(lo, ro);
	});
}

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: trimDate(moment()),
			byDate: {},
			supplementalDates: {},
			newDate: null
		};
		Object.assign(this.state, this.getSupplementalDates(this.state.tab));
		this.addDate = this.addDate.bind(this);
	}

	componentDidMount() {
		this.componentDidUpdate({}, {});
	}

	getSupplementalDates(time) {
		const { supplementalDates } = this.state;
		return {
			supplementalDates: Object.assign({}, supplementalDates, {
				[time]: {
					orders: [],
					date: moment(time)
				}
			})
		};
	}

	componentDidUpdate(props, state) {
		const { orders, person } = this.props;
		const { supplementalDates } = this.state;
		if(orders !== props.orders || supplementalDates !== state.supplementalDates) {
			const byDate = {};
			orders.forEach(order => {
				const date = moment(order.dateOverride || order.shipmentDate);
				const key = trimDate(date);
				if(!byDate[key]) {
					byDate[key] = {
						orders: [],
						date: moment(key)
					};
				}
				byDate[key].orders.push(order);
			});
			Object.keys(supplementalDates).forEach(tab => {
				if(!byDate[tab]) {
					byDate[tab] = supplementalDates[tab];
				}
			});
			Object.keys(byDate).forEach(key => {
				sortOrders(byDate[key].orders, person.role !== 'CUSTOMER_SERVICE');
			});
			this.setState({ byDate });
		}
	}

	addDate(e) {
		e.preventDefault();
		const { newDate } = this.state;
		if(newDate) {
			const tab = trimDate(newDate);
			this.setState(Object.assign({
				newDate: null
			}, this.getSupplementalDates(tab)));
			this.props.history.push(`/${tab}`);
		}
	}

	render() {
		const { person, match, history, location } = this.props;
		const hasParams = match && match.params;
		const tab = hasParams && match.params.tab || this.state.tab;
		const { byDate, newDate } = this.state;
		const query = location.search ? queryString.parse(location.search) : {};
		const now = moment().startOf('day');
		return <main className="container">
			<OrderSearch byDate={byDate} person={person} />
			{['CUSTOMER_SERVICE', 'TEAM_LEADER'].includes(person.role) && <Nav tabs className="order-day-tabs">
				{Object.keys(byDate).sort().map(key => {
					const { date } = byDate[key];
					const classes = ['nav-link'];
					if(tab === key) {
						classes.push('active');
					}
					const recent = Math.abs(date.diff(now, 'days')) <= 1;
					return <NavItem key={key}>
						<Link className={classes.join(' ')} to={`/${key}`}>
							{recent ? date.calendar(now, {
								sameDay: '[Today]',
								nextDay: '[Tomorrow]',
								lastDay: '[Yesterday]'
							}) : date.format('D MMM')}
						</Link>
					</NavItem>;
				})}
				{person.role === 'CUSTOMER_SERVICE' && <NavItem>
					<NavLink
						active={tab === 'new'}
						onClick={() => history.push('/new')}
						className="btn btn-primary"><i className="fas fa-plus"></i></NavLink>
				</NavItem>}
			</Nav>}
			<TabContent activeTab={tab}>
				{Object.keys(byDate).map(key =>
					<TabPane tabId={key} key={key}>
						{['CUSTOMER_SERVICE', 'TEAM_LEADER'].includes(person.role) && <OrderForm date={key} person={person} />}
						<OrderList
							orders={byDate[key].orders}
							role={person.role}
							tab={hasParams && match.params.sub}
							history={history}
							highlight={query.highlight}
							active={tab === key}
							date={key} />
					</TabPane>
				)}
				<TabPane tabId="new">
					<header>
						<form className="form-inline" onSubmit={this.addDate}>
							<DatePicker
								className="form-control"
								selected={newDate}
								placeholderText="Datum"
								dateFormat="yyyy/MM/dd"
								minDate={now}
								onChange={date => this.setState({ newDate: date })}
							/>
							<input type="submit" value="Submit" className="btn btn-primary" />
						</form>
					</header>
				</TabPane>
			</TabContent>
		</main>;
	}
}

export default connect(({ orders, person }) => {
	return { orders, person };
})(HomePage);
