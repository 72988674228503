/* eslint-disable no-magic-numbers */

function sortEven(min, max) {
	return (lhs, rhs) => {
		if(lhs.pad >= min && lhs.pad <= max && rhs.pad >= min && rhs.pad <= max) {
			if(lhs.pad === rhs.pad) {
				return 0;
			} else if((lhs.pad & 1) > (rhs.pad & 1)) {
				return -1;
			} else if((lhs.pad & 1) < (rhs.pad & 1)) {
				return 1;
			}
			let lb = (lhs.pad - min - (lhs.pad & 1)) / 2;
			lb -= lb & 1;
			let rb = (rhs.pad - min - (rhs.pad & 1)) / 2;
			rb -= rb & 1;
			return lb - rb;
		}
	};
}

function inOrder(...comps) {
	return (lhs, rhs) => {
		for(const comp of comps) {
			const d = comp(lhs, rhs);
			if(d !== undefined) {
				return d;
			}
		}
	};
}

const SORT_LOCATION = {
	12: sortEven(2, 16),
	14: inOrder(sortEven(14, 44), sortEven(2, 8)),
	18: inOrder(sortEven(2, 8), sortEven(14, 24)),
	21: sortEven(2, 24),
	22: sortEven(2, 52),
	23: sortEven(2, 28),
	25: inOrder(sortEven(2, 4), sortEven(8, 26)),
	26: sortEven(6, 32),
	30: sortEven(2, 24),
	31: sortEven(2, 32),
	32: sortEven(2, 24),
	35: sortEven(2, 24),
	36: sortEven(2, 12),
	37: sortEven(2, 20)
};

export default function sortLocation(lhs, rhs) {
	if(lhs.loods >= 30 && rhs.loods < 30) {
		return -1;
	} else if(lhs.loods < 30 && rhs.loods >= 30) {
		return 1;
	}
	let mult = 1;
	if(lhs.loods >= 20 && rhs.loods >= 20 && lhs.loods <= 25 && rhs.loods <= 25) {
		mult = -1;
	}
	if(lhs.loods > rhs.loods) {
		return mult;
	} else if(rhs.loods > lhs.loods) {
		return -mult;
	}
	const subSort = SORT_LOCATION[lhs.loods];
	if(subSort) {
		const d = subSort(lhs, rhs);
		if(d) {
			return d;
		} else if(d === undefined) {
			if(lhs.pad > rhs.pad) {
				return 1;
			} else if(lhs.pad < rhs.pad) {
				return -1;
			}
		}
	}
	if(lhs.location > rhs.location) {
		return 1;
	} else if(lhs.location < rhs.location) {
		return -1;
	}
	return 0;
}
