import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { addLocation } from '../../websocket';

function NewLocation({ stock }) {
	const [id, setId] = useState('');
	const [redirect, setRedirect] = useState(false);

	function submit(e) {
		e.preventDefault();
		addLocation(id);
		setRedirect(true);
	}

	if(redirect) {
		return <Redirect to="/" push={true} />;
	}
	return <section className="container">
		<h1>Nieuwe locatie</h1>
		<form onSubmit={submit}>
			<div className="form-group">
				<input
					type="text"
					className="form-control"
					placeholder="Code"
					value={id}
					onChange={e => setId(e.target.value.toUpperCase())}
					required />
			</div>
			<div className="form-group">
				<input
					type="submit"
					className="btn btn-primary"
					value="Save"
					disabled={!id || stock.find(({ _id }) => id === _id)} />
			</div>
		</form>
	</section>;
}

export default connect(({ stock }) => {
	return { stock };
})(NewLocation);
