import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { getStickers } from '../../api';

const DELAY = 500;

function getValues(order, autoSave) {
	return {
		cod: order.cod || (autoSave ? '' : 'NO'),
		delivery: order.delivery || (autoSave ? '' : 'TARGOFLEX'),
		term: order.term || (autoSave ? '' : 'DDP')
	};
}

function DeliveryForm({ codList, deliveryList, terms, className, children, order, onData, abc, post = null, autoSave = false }) {
	const [values, setValues] = useState(() => getValues(order, autoSave));
	const { cod, delivery, term } = values;
	const [state, setState] = useState({ submitting: false, print: true });
	const ignoreFirst = useRef(true);
	useEffect(() => {
		ignoreFirst.current = true;
		setValues(getValues(order, autoSave));
	}, [order, autoSave]);
	function submit(e) {
		if(e) {
			e.preventDefault();
		}
		const { print } = state;
		setState(prev => ({ ...prev, submitting: true }));
		Promise.all(abc.map(({ _id }) => getStickers({
			order: _id,
			cod: cod || null,
			delivery: delivery || null,
			term: term || null,
			print
		}).then(data => {
			data.date = new Date(data.date);
			return data;
		}))).then(data => {
			if(onData) {
				onData(data);
			}
			setState(prev => ({ ...prev, submitting: false }));
		}).catch(err => {
			console.error('Failed to get stickers', err);
			window.alert('Er is een fout opgetreden');
			setState(prev => ({ ...prev, submitting: false }));
		});
	}
	useEffect(() => {
		if(autoSave) {
			if(ignoreFirst.current) {
				ignoreFirst.current = false;
				return;
			}
			const timeout = setTimeout(submit, DELAY);
			return () => clearTimeout(timeout);
		}
	}, [cod, delivery, term, autoSave]);
	return <Form onSubmit={submit} className={className}>
		{children}
		<dl className="row mb-0">
			<dt className="col-6 col-md-4">Cash on Delivery</dt>
			<dd className="col-6 col-md-8">
				<Input type="select" value={cod} onChange={e => setValues(prev => ({ ...prev, cod: e.target.value } ))} required>
					<option value="" disabled>---</option>
					{codList.map(option => <option key={option} value={option}>
						{option}
					</option>)}
				</Input>
			</dd>
			<dt className="col-6 col-md-4">Delivery Option</dt>
			<dd className="col-6 col-md-8">
				<Input type="select" value={delivery} onChange={e => setValues(prev => ({ ...prev, delivery: e.target.value }))} required>
					<option value="" disabled>---</option>
					{deliveryList.map(option => <option key={option.id} value={option.id}>
						{option.description}
					</option>)}
				</Input>
			</dd>
			<dt className="col-6 col-md-4">Incoterm</dt>
			<dd className="col-6 col-md-8">
				<Input type="select" value={term} onChange={e => setValues(prev => ({ ...prev, term: e.target.value }))} required>
					<option value="" disabled>---</option>
					{terms.map(option => <option key={option.id} value={option.id}>
						{option.description}
					</option>)}
				</Input>
			</dd>
		</dl>
		{!autoSave && <label className="d-block">
			<input type="checkbox" checked={state.print} onChange={e => setState(prev => ({ ...prev, print: e.target.checked }))} /> Stuur naar label printer
		</label>}
		{post && post(state.submitting)}
	</Form>;
}

export default connect(({ person, cod, delivery, terms }) => {
	return { person, codList: cod, deliveryList: delivery, terms };
})(DeliveryForm);
