import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { getTab } from './OrderList';
import { findOrder } from '../../api';
import { trimDate } from '../../helper';

const DELAY = 1000;

class OrderSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			redirect: null,
			found: null,
			hash: null
		};
		this.submit = this.submit.bind(this);
		this.timeout = 0;
	}

	findOrder(search) {
		const { byDate } = this.props;
		let order;
		let date = Object.keys(byDate).find(d => {
			order = byDate[d].orders.find(({ code }) => code.includes(search));
			return order;
		});
		if(date) {
			return Promise.resolve({ date, order });
		} else if(this.props.person.role === 'CUSTOMER_SERVICE') {
			return findOrder(search).then(orders => {
				const o = orders[0];
				date = trimDate(o.dateOverride || o.shipmentDate);
				return { date, order: o };
			});
		}
		return Promise.reject(new Error('Not found'));
	}

	submit(e) {
		e.preventDefault();
		const search = this.state.search.trim();
		if(search === '') {
			return;
		}
		this.findOrder(search).then(({ date, order }) => {
			const id = encodeURIComponent(order._id);
			this.setState({
				found: true,
				redirect: `/${date}/${getTab(order)}?highlight=${id}`,
				hash: `#${id}`
			});
		}).catch(() => {
			this.setState({
				found: false
			});
		}).then(() => {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => this.setState({ found: null }), DELAY);
		});
	}

	componentDidUpdate() {
		const { redirect, hash } = this.state;
		const { location } = this.props;
		if(redirect && location && location.pathname + location.search === redirect) {
			this.setState({ redirect: null, hash: null }, () => {
				window.location = hash;
			});
		}
	}

	render() {
		const { search, found, redirect } = this.state;
		const classes = ['form-control'];
		if(found !== null) {
			if(found) {
				classes.push('is-valid');
			} else {
				classes.push('is-invalid');
			}
		}
		return <form className="form-inline" onSubmit={this.submit}>
			<div className="form-group">
				<input
					type="search"
					className={classes.join(' ')}
					placeholder="Zoek op ordernummer"
					value={search}
					required
					onChange={e => this.setState({ search: e.target.value })} />
			</div>
			<input type="submit" className="btn btn-primary" value="Zoek" />
			{redirect && <Redirect to={redirect} />}
		</form>;
	}
}

export default withRouter(OrderSearch);
