import React, { Component, Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { getMovingItems, getImage, deleteMovingItem, setMovingItemPerson } from '../../api';

class MovingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			moving: null
		};
	}

	componentDidMount() {
		getMovingItems().then(moving => {
			this.setState({ moving });
		}).catch(err => {
			window.alert(err.message);
		});
	}

	selectManager(line) {
		return e => {
			const { value } = e.target;
			this.setState(state => {
				const index = state.moving.indexOf(line);
				if(index >= 0) {
					const clone = Object.assign({}, state);
					clone.moving = clone.moving.slice();
					clone.moving[index] = Object.assign({}, line);
					clone.moving[index].person = value;
					return clone;
				}
				return state;
			});
			setMovingItemPerson(line.item, line.person, value).catch(() => {
				this.setState(state => {
					const index = state.moving.findIndex(mLine => mLine.item === line.item && mLine.person === value);
					if(index >= 0) {
						const clone = Object.assign({}, state);
						clone.moving = clone.moving.slice();
						clone.moving[index] = Object.assign({}, line);
						clone.moving[index].person = line.person;
						return clone;
					}
					return state;
				});
			});
		};
	}

	delete(line) {
		return () => {
			this.setState(state => {
				const clone = Object.assign({}, state);
				clone.moving = clone.moving.filter(l => l !== line);
				return clone;
			});
			deleteMovingItem(line.item, line.person).catch(() => {
				this.setState(state => {
					const clone = Object.assign({}, state);
					clone.moving = clone.moving.concat(line);
					return clone;
				});
			});
		};
	}

	render() {
		const { persons } = this.props;
		const { moving } = this.state;
		return <section className="container">
			<h1>Wordt verplaatst</h1>
			<Table>
				<thead>
					<tr>
						<th colSpan={2}>Item</th>
						<th>Persoon</th>
						<th>Datum</th>
						<th className="text-right">Hoeveelheid</th>
						<th className="text-right">Voorraad</th>
						<th>Locaties</th>
						<th className="text-center">Verwijder</th>
					</tr>
				</thead>
				<tbody>
					{moving && moving.map(item => {
						return <tr key={`${item.item}_${item.person}`}>
							<td className="item"><img src={getImage(item.item)} alt="" /></td>
							<td>{item.item}</td>
							<td>
								<select
									className="form-control" value={item.person || ''}
									onChange={this.selectManager(item)}>
									<option disabled value="">Verplaatser</option>
									{persons.filter(p => p.roles.includes('MOVE_ITEMS')).map(p =>
										<option key={p._id} value={p._id}>{p.name}</option>
									)}
								</select>
							</td>
							<td>{item.date && moment(item.date).format('DD/MM HH:mm')}</td>
							<td className="text-right">{item.quantity}</td>
							<td className="text-right">{item.stock || 0}</td>
							<td>{(item.locations || []).map(location => {
								return <Fragment key={location}>
									<Link to={`/stock/${encodeURIComponent(location)}`}>{location}</Link><br />
								</Fragment>;
							})}</td>
							<td className="text-center">
								<Button color="danger" size="sm" onClick={this.delete(item)} title="Delete"><i className="fas fa-times"></i></Button>
							</td>
						</tr>;
					})}
				</tbody>
			</Table>
		</section>;
	}
}

export default connect(({ persons }) => {
	return { persons };
})(MovingPage);
