import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ListOrder from './ListOrder';
import ElstSwitch from './ElstSwitch';
import { cbcmToCbm } from '../../dimensions';
import { updateOrder } from '../../websocket';
import { format, trimDate } from '../../helper';
import { groupOrders } from '../order/AbcOrder';

export default class OrderTab extends Component {
	constructor(props) {
		super(props);
		this.download = this.download.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.changeDate = this.changeDate.bind(this);
		this.state = {
			b2c: true,
			modal: false,
			newDate: null
		};
	}

	openModal(order) {
		return e => {
			e.preventDefault();
			this.setState({ modal: order });
		};
	}

	toggleModal(e) {
		if(e) {
			e.preventDefault();
		}
		this.setState({
			modal: !this.state.modal
		});
	}

	changeDate() {
		const { newDate, modal } = this.state;
		if(newDate && modal) {
			updateOrder(modal._id, {
				dateOverride: trimDate(newDate)
			});
			this.setState({
				newDate: null,
				modal: false
			});
		}
	}

	downloadFile(uri) {
		const link = document.createElement('a');
		if(typeof link.download === 'string') {
			link.href = uri;
			link.download = `${Date.now()}.csv`;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			window.location = uri;
		}
	}

	download() {
		const reader = new FileReader();
		reader.onload = e => {
			this.downloadFile(e.target.result);
		};
		const { orders } = this.props.cat;
		const items = {};
		orders.forEach(order => {
			order.lines.forEach(line => {
				if(!items[line.item.code]) {
					items[line.item.code] = 0;
				}
				items[line.item.code] += line.quantity;
			});
		});
		const csv = Object.keys(items).map(code => {
			return `"${code}","${items[code]}"`;
		}).join('\n');
		reader.readAsDataURL(new Blob(['"Item","Quantity"\n', csv], { type: 'text/csv' }));
	}

	getOrderGroups() {
		const { cat, role } = this.props;
		const { b2c } = this.state;
		let remaining = cat.orders;
		if(!b2c) {
			remaining = remaining.filter(o => !o.code.startsWith('B2C'));
		}
		const out = [];
		while(remaining.length) {
			const group = groupOrders(role, remaining[0], remaining);
			out.push(group);
			remaining = remaining.filter(o => !group.includes(o));
		}
		return out;
	}

	render() {
		const { cat, highlight, role } = this.props;
		const { b2c, modal, newDate } = this.state;
		const customer = {
			order: null,
			dropship: null
		};
		return <Fragment>
			<p>
				{cbcmToCbm(cat.size)} m<sup>3</sup>, {role !== 'PACKAGER' ? `${cat.pallets} pallet(s), ` : ''}{format('EUR', cat.price)}
				{(role === 'CUSTOMER_SERVICE' || role === 'TEAM_LEADER') && <span className="color-unknown"> + {format('EUR', cat.unknownPrice)}</span>}
				{role === 'CUSTOMER_SERVICE' && <button className="btn btn-sm btn-primary float-right" title="Download items" onClick={this.download}>
					<i className="fas fa-file-download"></i>
				</button>}
			</p>
			{cat.filterB2C ? <p>
				<label>Toon B2C orders <input type="checkbox" checked={b2c} onChange={e => this.setState({ b2c: e.target.checked })} /></label>
			</p> : null}
			{role === 'TEAM_LEADER' && <ElstSwitch />}
			<ol>
				{this.getOrderGroups().map(abc => {
					const order = abc[0];
					const first = customer.dropship !== order.dropship || !customer.dropship && order.customer.code !== customer.code;
					if(first) {
						customer.dropship = order.dropship;
						customer.code = order.customer.code;
					}
					return <ListOrder
						key={order._id}
						order={order}
						first={first}
						abc={abc}
						openModal={this.openModal(order)}
						highlight={!!highlight && abc.some(o => o._id === highlight)} />;
				})}
			</ol>
			<Modal isOpen={!!modal} toggle={this.toggleModal}>
				<ModalHeader toggle={this.toggleModal}>{modal.code}</ModalHeader>
				<ModalBody>
					<DatePicker
						className="form-control"
						selected={newDate}
						placeholderText="Datum"
						dateFormat="yyyy/MM/dd"
						minDate={moment().startOf('day').toDate()}
						onChange={d => this.setState({ newDate: d })} />
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={this.changeDate}>Save</Button>
					<Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
				</ModalFooter>
			</Modal>
		</Fragment>;
	}
}
