import moment from 'moment';

const formatters = {};
const locales = window.navigator && window.navigator.languages ? window.navigator.languages : ['en'];

export const ROLES = {
	CUSTOMER_SERVICE: 'Customer Service',
	TEAM_LEADER: 'Teamleider',
	PICKER: 'Orderpicker',
	MANAGER: 'Loodsbeheerder',
	ADMIN: 'Gebruikersbeheerder',
	PACKAGER: 'Inpakker'
};
const CURRENCY_CODE_LENGTH = 3;
const NAN_FORMAT = {
	format() {
		return NaN;
	}
};

export function format(currency, price) {
	if(isNaN(price)) {
		return price;
	} else if(!formatters[currency]) {
		try {
			const numberFormat = new Intl.NumberFormat(locales, {
				style: 'currency', currency: (currency || 'EUR').slice(0, CURRENCY_CODE_LENGTH)
			});
			formatters[currency] = numberFormat;
		} catch(err) {
			console.warn(`Failed to create number format for ${currency}`, err);
			formatters[currency] = NAN_FORMAT;
		}
	}
	return formatters[currency].format(price);
}

export function trimDate(date) {
	return moment(date).format('YYYY-MM-DD');
}

export function splitLocation(location) {
	const loods = 2;
	const pad = 4;
	return {
		loods: parseInt(location.slice(0, loods), 10),
		pad: location.slice(loods, pad),
		rek: location.slice(pad),
		location
	};
}

export function stringifyLocation(place) {
	if(isNaN(place.loods)) {
		return place.location;
	}
	return `${place.loods} ${place.pad} ${place.rek}`;
}
