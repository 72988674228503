import React, { Component } from 'react';
import HttpStatus from 'http-status-codes';
import { Link } from 'react-router-dom';
import { addOrder } from '../../api';
import Reports from '../management/Reports';

export default class OrderForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: '',
			submitting: false
		};
		this.addOrder = this.addOrder.bind(this);
	}

	addOrder(e) {
		e.preventDefault();
		const { order, submitting } = this.state;
		const code = order.trim();
		if(code && !submitting) {
			this.setState({ submitting: true });
			addOrder(code, this.props.date, e.target.value === 'Gebruik pakketdienst').then(() => {
				this.setState({ order: '', submitting: false });
			}).catch(err => {
				this.setState({ submitting: false });
				if(err.response) {
					const { status } = err.response;
					if(status === HttpStatus.NOT_FOUND) {
						window.alert('Order niet gevonden');
					} else if(status === HttpStatus.CONFLICT) {
						window.alert('Order bestaat al');
					} else if(status === HttpStatus.PRECONDITION_FAILED) {
						window.alert('De items in deze order zijn niet aanwezig');
					}
				}
				console.error('Failed to add order', err);
			});
		}
	}

	render() {
		const { order, submitting } = this.state;
		return <header>
			<div className="row">
				<div className="col-md-6">
					{this.props.person.role === 'CUSTOMER_SERVICE' && <form onSubmit={this.addOrder} className="form-inline">
						<input
							className="form-control"
							type="text"
							placeholder="Order Nummer"
							value={order}
							onChange={e => this.setState({ order: e.target.value })} />
						<input type="submit" value="Voeg toe" className="btn btn-primary" disabled={submitting} />
						<input type="submit" value="Gebruik pakketdienst" className="btn btn-info" onClick={this.addOrder} disabled={submitting} />
					</form>}
				</div>
				<div className="col-md-6 text-right">
					{this.props.person.role === 'CUSTOMER_SERVICE' && <p>
						<Link to={`/edi/${this.props.date}`} className="btn btn-primary">Dachser EDI & facturatie</Link>
						{' '}
						<Reports date={this.props.date} cs={true} />
					</p>}
				</div>
			</div>
		</header>;
	}
}
