import React, { useState } from 'react';
import { breakItems } from '../../api';
import ItemForm from './ItemForm';

export default function BreakPage() {
	const [lines, setLines] = useState([]);
	const [error, setError] = useState(null);

	function save() {
		const toBreak = [];
		lines.forEach(line => {
			if(line.location && line.item && line.quantity > 0) {
				toBreak.push({
					location: line.location,
					item: line.item,
					quantity: -line.quantity
				});
			}
		});
		setError(null);
		breakItems(toBreak).then(() => {
			setLines([]);
		}).catch(err => {
			setError(err.message);
		});
	}

	return <ItemForm lines={lines} error={error} setLines={setLines} save={save} useItemLocations />;
}
