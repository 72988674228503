import React from 'react';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';

export default function App() {
	return <>
		<LoggedIn />
		<LoggedOut />
	</>;
}
