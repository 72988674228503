import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Autocomplete from '../Autocomplete';
import { getContainer } from '../../api';
import { addContainer } from '../../websocket';
import { sortLocations } from './LocationList';
const MAX_RESULTS = 10;

class Container extends Component {
	constructor(props) {
		super(props);
		this.state = {
			container: '',
			data: null,
			id: ''
		};
		if(props.location && props.location.search) {
			const { container } = queryString.parse(props.location.search);
			this.state.container = container || '';
		}
		this.submit = this.submit.bind(this);
		this.save = this.save.bind(this);
	}

	submit(e) {
		e.preventDefault();
		const { container } = this.state;
		getContainer(container).then(data => {
			const { stock } = this.props;
			const locations = {};
			stock.forEach(({ _id, items }) => {
				items.forEach(item => {
					if(item.quantity > 0) {
						if(!(item.item in locations)) {
							locations[item.item] = [];
						}
						locations[item.item].push(_id);
					}
				});
			});
			this.setState({
				id: container,
				data: data.map(line => {
					line.locations = locations[line.item] || [];
					line.newLocations = [];
					line.differences = [];
					line.received = line.quantity;
					return line;
				}).sort((lhs, rhs) => {
					if(lhs.item > rhs.item) {
						return 1;
					} else if(lhs.item < rhs.item) {
						return -1;
					}
					return 0;
				})
			});
		}).catch(err => {
			console.error('Failed to get container', err);
			window.alert(err.message);
		});
	}

	updateLocation(i, updater) {
		const data = this.state.data.slice();
		data[i] = Object.assign({}, data[i]);
		updater(data[i]);
		this.setState({ data });
	}

	addLocation(i) {
		return () => {
			const { stock } = this.props;
			this.updateLocation(i, loc => {
				loc.newLocations = loc.newLocations.concat({
					location: '',
					quantity: 0,
					matches: stock.slice(0, MAX_RESULTS)
				});
			});
		};
	}

	changeLocation(i, n) {
		const { stock } = this.props;
		return (e, value) => {
			this.updateLocation(i, loc => {
				const search = value.trim().toUpperCase();
				let matches = loc.newLocations[n].matches;
				if(search) {
					const split = search.split(/\s+/);
					matches = stock.filter(({ _id }) =>
						split.every(term => _id.includes(term))).slice(0, MAX_RESULTS);
				}
				const newLocation = Object.assign({}, loc.newLocations[n], {
					location: value,
					matches
				});
				loc.newLocations = loc.newLocations.slice(0, n)
					.concat(newLocation, loc.newLocations.slice(n + 1));
			});
		};
	}

	selectLocation(i, n) {
		return (value, location) => {
			this.updateLocation(i, loc => {
				const newLocation = Object.assign({}, loc.newLocations[n], {
					location: location._id
				});
				loc.newLocations = loc.newLocations.slice(0, n)
					.concat(newLocation, loc.newLocations.slice(n + 1));
			});
		};
	}

	changeQuantity(i, n) {
		return e => {
			this.updateLocation(i, loc => {
				const newLocation = Object.assign({}, loc.newLocations[n], {
					quantity: e.target.value
				});
				loc.newLocations = loc.newLocations.slice(0, n)
					.concat(newLocation, loc.newLocations.slice(n + 1));
			});
		};
	}

	changeReceived(i) {
		return e => {
			this.updateLocation(i, loc => {
				loc.received = e.target.value;
			});
		};
	}

	addDifference(i) {
		return () => {
			this.updateLocation(i, loc => {
				loc.differences = loc.differences.concat({
					reason: '',
					quantity: 0
				});
			});
		};
	}

	changeDifference(i, n) {
		return e => {
			this.updateLocation(i, loc => {
				loc.differences = loc.differences.slice();
				loc.differences[n] = Object.assign({}, loc.differences[n]);
				loc.differences[n].quantity = e.target.value;
			});
		};
	}

	changeReason(i, n) {
		return e => {
			this.updateLocation(i, loc => {
				loc.differences = loc.differences.slice();
				loc.differences[n] = Object.assign({}, loc.differences[n]);
				loc.differences[n].reason = e.target.value;
			});
		};
	}

	save() {
		const perLocation = {};
		const perItem = {};
		this.state.data.forEach(line => {
			perItem[line.item] = {
				expected: parseInt(line.quantity, 10),
				received: parseInt(line.received, 10),
				differences: line.differences.filter(d => d.reason && d.quantity > 0)
			};
			perItem[line.item].differences = perItem[line.item].differences.map(d => {
				return { reason: d.reason, quantity: parseInt(d.quantity, 10) };
			});
			line.newLocations.forEach(location => {
				if(!perLocation[location.location]) {
					perLocation[location.location] = {};
				}
				if(!perLocation[location.location][line.item]) {
					perLocation[location.location][line.item] = 0;
				}
				perLocation[location.location][line.item] += parseInt(location.quantity, 10);
			});
		});
		addContainer(this.state.id, perLocation, perItem);
		this.setState({ data: null, id: '' });
	}

	render() {
		const existingLocations = {};
		this.props.stock.forEach(l => {
			existingLocations[l._id] = true;
		});
		const { container, data } = this.state;
		let canSave = !!data;
		const canEdit = this.props.person.roles.includes('EDIT_LOCATIONS');
		return <section>
			<div className="container">
				<h1>Locaties per container</h1>
				<form className="form-inline" onSubmit={this.submit}>
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							placeholder="Containernummer"
							value={container}
							onChange={e => this.setState({ container: e.target.value })} />
					</div>
					<div className="form-group">
						<input type="submit" value="Laad" className="btn btn-primary" disabled={!container} />
					</div>
				</form>
				<hr />
			</div>
			{data && <div className="container-fluid">
				<Table>
					<thead>
						<tr>
							<th>Item</th>
							<th>Verwacht</th>
							<th>Locaties</th>
							{canEdit && <th>Geleverd</th>}
							{canEdit && <th>Op te boeken</th>}
							{canEdit && <th>Opboeken</th>}
							{canEdit && <th>Verschil</th>}
						</tr>
					</thead>
					<tbody>
						{data.map((line, i) => {
							let error;
							let filled = 0;
							const fields = canEdit && line.newLocations.map((loc, n) => {
								const q = parseInt(loc.quantity, 10);
								if(!isNaN(q)) {
									filled += q;
								}
								if(!existingLocations[loc.location]) {
									error = true;
									canSave = false;
								}
								return <div className="row" key={n}>
									<div className="col-md-6">
										<Autocomplete
											getItemValue={l => l._id}
											onChange={this.changeLocation(i, n)}
											onSelect={this.selectLocation(i, n)}
											items={loc.matches}
											value={loc.location} />
									</div>
									<div className="col-md-6">
										<input
											type="number"
											min={0}
											value={loc.quantity}
											className="form-control"
											onChange={this.changeQuantity(i, n)} />
									</div>
								</div>;
							});
							let explained = 0;
							const differences = canEdit && line.differences.map((difference, n) => {
								const q = parseInt(difference.quantity, 10);
								if(!isNaN(q)) {
									explained += q;
								}
								return <div className="row" key={n}>
									<div className="col-md-6">
										<select className="form-control" value={difference.reason} onChange={this.changeReason(i, n)}>
											<option disabled value="">Reden</option>
											<option value="doa">Breuk in levering</option>
											<option value="gravity">Laten pleuren</option>
										</select>
									</div>
									<div className="col-md-6">
										<input
											type="number"
											min={0}
											value={difference.quantity}
											className="form-control"
											onChange={this.changeDifference(i, n)} />
									</div>
								</div>;
							});
							const diff = line.received - filled - explained;
							error |= diff;
							return <tr key={i} className={error ? 'bg-warning' : ''}>
								<td>{line.item}</td>
								<td>{line.quantity}</td>
								<td>{line.locations.map(location => <Fragment>
									<Link to={`/stock/${location}`}>{location}</Link>{' '}
								</Fragment>)}</td>
								{canEdit && <td><input type="number" value={line.received} onChange={this.changeReceived(i)} className="form-control" /></td>}
								{canEdit && <td>{diff}</td>}
								{canEdit && <td>
									{fields}
									<button className="btn btn-success btn-sm float-right" onClick={this.addLocation(i)}>+</button>
								</td>}
								{canEdit && <td>
									{differences}
									<button className="btn btn-success btn-sm float-right" onClick={this.addDifference(i)}>+</button>
								</td>}
							</tr>;
						})}
					</tbody>
				</Table>
				{canEdit && <button className="btn btn-success" disabled={!canSave} onClick={this.save}>Opboeken</button>}
			</div>}
		</section>;
	}
}

export default connect(({ stock, person }) => {
	return { stock: stock.slice().sort(sortLocations), person };
})(Container);
