import React, { Component, Fragment } from 'react';
import { changeState, setPicked, setComments } from '../../websocket';
import Swipe from '../Swipe';

const BACKGROUND = {
	NONE: 'bg-light',
	SUCCESS: 'bg-success',
	FAILED: 'bg-danger'
};
const THRESHOLD = {
	x: 200,
	y: 50
};
const ORDER = ['NONE', 'SUCCESS', 'FAILED'];

function LineHeader({ location, item, order, quantity, status }) {
	const itemCode = <>
		{order.interesting && order.interesting.includes(item.code) ? <><i className="fas fa-bullhorn" title="Aandacht vereist"></i>{' '}</> : null}
		{item.code[0]}
		<span className="relevant-number">
			{item.code.slice(1)}
		</span>
	</>;
	if(!order.dropship || status === 'NONE') {
		return <div className="row">
			<h2 className="col-6 col-md-4">{location}</h2>
			<h2 className="col-6 col-md-4 text-right text-md-center">{itemCode}</h2>
			<div className="col-12 col-md-4 text-right">pick <h2 className="d-inline">{quantity}</h2></div>
		</div>;
	}
	return <div className="row">
		<h2 className="col-6 col-md-3">{location}</h2>
		<h2 className="col-6 col-md-3 text-right text-md-center">{itemCode}</h2>
		<h2 className="col-6 col-md-3 text-md-center">{order.customer.name}</h2>
		<div className="col-6 col-md-3 text-right">pick <h2 className="d-inline">{quantity}</h2></div>
	</div>;
}

export default class OrderLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			picked: props.line.picked || ''
		};
	}

	componentDidUpdate(props) {
		const { picked } = this.props.line;
		if(props !== this.props && picked !== undefined && this.state.picked !== picked) {
			this.setState({ picked });
		}
	}

	changeStatus(location) {
		return ({ x, y }) => {
			if(location === 'Wordt verplaatst') {
				return;
			}
			const aX = Math.abs(x);
			const aY = Math.abs(y);
			if(aX < THRESHOLD.x || aY > aX || aY > THRESHOLD.y) {
				return;
			}
			const { order, line } = this.props;
			if(order.status !== 'NONE' || !order.pickers.length) {
				return;
			}
			const index = (ORDER.length + ORDER.indexOf(line.status) + Math.sign(x)) % ORDER.length;
			changeState(order._id, line._id, location, ORDER[index]);
		};
	}

	setPicked(location) {
		return e => {
			const { order, line } = this.props;
			const picked = parseInt(e.target.value, 10);
			this.setState({ picked });
			if(!isNaN(picked) && picked >= 0 && picked <= line.quantity) {
				setPicked(order._id, line._id, location, picked);
			}
		};
	}

	setComments(location) {
		return e => {
			const { order, line } = this.props;
			setComments(order._id, line._id, location, e.target.value);
		};
	}

	renderLine() {
		const total = this.props.quantity;
		const { location, line, role, order } = this.props;
		const { item, quantity, status, blocked, comments } = line;
		const locations = Object.keys(line.locations).length;
		return <Fragment>
			{role !== 'PACKAGER' && <LineHeader location={location} item={item} order={order} quantity={quantity} status={status} />}
			<div className={BACKGROUND[status]}>
				<div className="row">
					<div className="col-3 col-md-3 col-lg-1">
						<img alt="" src={item.image} />
					</div>
					<div className="col-9 col-md-3 col-lg-3">
						{item.description}
					</div>
					<div className="col-12 col-md-6 col-lg-6">
						<div className="row">
							<div className="col-6">
								{item.length} &times; {item.width} &times; {item.height} cm<br />
								{blocked && <Fragment>Geblokkeerd<br /></Fragment>}
								{comments || ''}
							</div>
							<div className="col-6">
								{role !== 'PACKAGER' ? <Fragment>
									Voorraad: {total}<br />
									{locations > 1 ? `Andere locaties: ${locations}` : null}
								</Fragment> : <Fragment>
									{item.code[0]}
									<span className="relevant-number">
										{item.code.slice(1)}
									</span><br />
									{quantity}
								</Fragment>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>;
	}

	render() {
		const { place, line, role } = this.props;
		const { quantity, status, comments } = line;
		const { picked } = this.state;
		return <Fragment>
			{role !== 'PACKAGER' ? <Swipe onSwipe={this.changeStatus(place)}>
				{this.renderLine()}
			</Swipe> : <div>{this.renderLine()}</div>}
			{status === 'FAILED' && <div>
				Picked:
				<input
					type="number"
					className="form-control"
					min={0} max={quantity}
					value={picked}
					disabled={role === 'PACKAGER'}
					onChange={this.setPicked(place)} />
				{role === 'PICKER' && <Fragment>
					Comments:
					<input
						type="text"
						className="form-control"
						value={comments}
						disabled={role === 'PACKAGER'}
						onChange={this.setComments(place)} />
				</Fragment>}
			</div>}
		</Fragment>;
	}
}
