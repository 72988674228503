import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

const THEME = {
	container: 'dropdown',
	containerOpen: '',
	input: 'form-control',
	inputFocused: '',
	suggestionsContainer: 'dropdown-menu',
	suggestionsContainerOpen: 'show shadow-sm',
	suggestionsList: 'list-group',
	suggestion: 'dropdown-item',
	suggestionFirst: '',
	suggestionHighlighted: 'active',
	sectionContainer: '',
	sectionContainerFirst: '',
	sectionTitle: ''
};

const NO_OP = () => void 0;

export default class AutocompleteInput extends React.Component {
	static get propTypes() {
		return {
			getItemValue: PropTypes.func.isRequired,
			onSelect: PropTypes.func.isRequired,
			onChange: PropTypes.func.isRequired,
			items: PropTypes.array.isRequired
		};
	}

	constructor(props) {
		super(props);
		this.state = {
			value: props.value
		};
		this.onChange = this.onChange.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { value } = this.props;
		if(value !== prevProps.value && prevProps.value === this.state.value) {
			this.setState({ value });
		}
	}

	onChange(event, { newValue: value }) {
		this.setState({ value });
		this.props.onChange(event, value);
	}

	onSelect(event, { suggestion: item, suggestionValue: value }) {
		this.setState({ value });
		this.props.onSelect(value, item);
	}

	render() {
		const { getItemValue, items } = this.props;
		return <Autosuggest
			getSuggestionValue={getItemValue}
			suggestions={items}
			onSuggestionSelected={this.onSelect}
			onSuggestionsFetchRequested={NO_OP}
			onSuggestionsClearRequested={NO_OP}
			renderSuggestion={getItemValue}
			inputProps={{
				onChange: this.onChange,
				value: this.state.value
			}}
			theme={THEME} />;
	}
}
