import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { splitLocation, stringifyLocation } from '../../helper';
import { getImage, getMovingItems, MAX_MOVING } from '../../api';
import { moveItems, blockItems } from '../../websocket';
import '../../css/locations.css';

function sortItems(lhs, rhs) {
	if(lhs.item < rhs.item) {
		return -1;
	} else if(lhs.item > rhs.item) {
		return 1;
	}
	return 0;
}

class LocationPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blocked: []
		};
	}

	componentDidUpdate(props) {
		if(this.props.location !== props.location) {
			this.setState({ blocked: [] });
		}
	}

	move(item) {
		const { location } = this.props;
		return e => {
			e.preventDefault();
			if(item.quantity > 0 && !this.state.blocked.includes(item.item)) {
				getMovingItems().then(moving => {
					if(moving.length >= MAX_MOVING) {
						return window.alert(`Het is niet mogelijk meer dan ${MAX_MOVING} items tegelijk te verplaatsen.`);
					}
					this.setState(state => {
						const blocked = state.blocked.slice();
						blocked.push(item.item);
						const clone = Object.assign({}, state);
						clone.blocked = blocked;
						return clone;
					});
					moveItems(location._id, {
						[item.item]: -item.quantity
					});
				}).catch(err => {
					window.alert(err.message);
				});
			}
		};
	}

	block(item) {
		return e => {
			const blocked = e.target.checked;
			blockItems(this.props.location._id, [{
				item, blocked
			}]);
		};
	}

	render() {
		const { location, highlight, person } = this.props;
		const canMove = person.role === 'MOVE_ITEMS';
		const canEdit = !canMove && person.roles.includes('EDIT_LOCATIONS');
		return <section className="container">
			<h1>{stringifyLocation(splitLocation(location._id))}</h1>
			{canEdit && <p><Link to={`/stock/edit/${location._id}`} className="btn btn-primary">Bewerk</Link></p>}
			<Table>
				<thead>
					<tr>
						<th colSpan={2}>Item</th>
						<th>Hoeveelheid</th>
						{canEdit && <th className="text-center">Geblokkeerd</th>}
						{canMove && <th>Verplaats</th>}
					</tr>
				</thead>
				<tbody className="locations">
					{location.items.slice().sort(sortItems).map(item => <tr key={item.item} className={item.item.includes(highlight) ? 'highlight' : ''}>
						<td className="item"><img src={getImage(item.item)} alt="" /></td>
						<td>{item.item}</td>
						<td>{item.quantity}</td>
						{canEdit && <td className="text-center">
							<input type="checkbox" checked={item.blocked || false} onChange={this.block(item.item)} />
						</td>}
						{canMove && <td>
							{item.quantity > 0 && <button
								className="btn btn-primary"
								onClick={this.move(item)}>
								<i className="fas fa-people-carry"></i>
							</button>}
						</td>}
					</tr>)}
				</tbody>
			</Table>
		</section>;
	}
}

function Page(props) {
	const { stock, match, person } = props;
	const location = stock.find(({ _id }) => _id === match.params.id);
	if(!location) {
		return null;
	}
	const query = props.location.search ? queryString.parse(props.location.search) : {};
	return <LocationPage
		location={location}
		stock={stock}
		person={person}
		highlight={query.highlight}
		locations={stock.map(loc => loc._id).sort().map(loc => {
			return {
				value: loc,
				label: loc
			};
		})}
	/>;
}

export default connect(({ stock, person }) => {
	return { stock, person };
})(Page);
