import React, { useState } from 'react';
import { returnItems } from '../../api';
import ItemForm from './ItemForm';

export default function ReturnPage() {
	const [lines, setLines] = useState([]);
	const [error, setError] = useState(null);

	function save() {
		const toReturn = [];
		lines.forEach(line => {
			if(line.location && line.item && line.quantity > 0) {
				toReturn.push({
					location: line.location,
					item: line.item,
					quantity: line.quantity
				});
			}
		});
		setError(null);
		returnItems(toReturn).then(() => {
			setLines([]);
		}).catch(err => {
			setError(err.message);
		});
	}

	return <ItemForm lines={lines} error={error} setLines={setLines} save={save} />;
}
