import React from 'react';
import { connect } from 'react-redux';
import { trimDate } from '../../helper';

function sameAddress(a1, a2, ignoreName) {
	if(a1 === a2) {
		return true;
	} else if(!a1 || !a2) {
		return false;
	}
	const keys = ['address', 'address2', 'postalCode', 'city', 'country'];
	if(!ignoreName) {
		keys.push('name', 'name2');
	}
	for(const key of keys) {
		if(a1[key] !== a2[key]) {
			return false;
		}
	}
	return true;
}

function filter(order, orders) {
	const date = trimDate(order.dateOverride);
	return orders.filter(o => {
		return order === o
			|| o.status === order.status
			&& trimDate(o.dateOverride) === date
			&& order.pickers.length === o.pickers.length && order.pickers.every(p => o.pickers.includes(p))
			&& (o.dropship && order.dropship
			|| o.customer.code === order.customer.code
			&& sameAddress(order.address, o.address, o.dropship && order.dropship && !o.splitByName && !order.splitByName));
	});
}

function filterDropship(order, orders) {
	const date = trimDate(order.dateOverride);
	return orders.filter(o => {
		return order === o
			|| o.customer.code === order.customer.code
			&& o.status === order.status
			&& trimDate(o.dateOverride) === date
			&& sameAddress(order.address, o.address, o.dropship && order.dropship && !o.splitByName && !order.splitByName);
	});
}

export function groupOrders(role, order, orders) {
	if(role === 'PACKAGER') {
		return filterDropship(order, filter(order, orders));
	} else if(['PICKER', 'TEAM_LEADER'].includes(role) && (order.dropship || order.pickers.length)) {
		return filter(order, orders);
	}
	return [order];
}

function AbcOrder({ orders, match, component: Child, person, ...props }) {
	const order = orders.find(({ _id }) => _id === match.params.plan);
	if(!order) {
		return null;
	}
	const abc = groupOrders(person.role, order, orders);
	return <Child order={order} person={person} abc={abc} {...props} />;
}

export default connect(({ orders, person }) => {
	return { orders, person };
})(AbcOrder);
