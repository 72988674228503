import React from 'react';
import Switch from '../Switch';
import { connect } from 'react-redux';
import { setPickedUpByElst } from '../../websocket';

function ElstSwitch({ value }) {
	return <>
		Elst langs geweest <Switch value={value} onChange={e => {
			setPickedUpByElst(e.target.checked);
		}} />
	</>;
}

export default connect(({ elstPickedUp }) => {
	return { value: elstPickedUp };
})(ElstSwitch);
