import React, { Component } from 'react';
import { Alert, Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Autocomplete from '../Autocomplete';
import { connect } from 'react-redux';
import { sortLocations } from './LocationList';
import { moveMultiple, getMovingItems } from '../../api';
const MAX_RESULTS = 10;
const MAX_DIFF = 10;

function getId(location) {
	return location._id;
}

class MoveMultiple extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
		this.state = {
			lines: [],
			error: null,
			redirect: false,
			moving: []
		};
	}

	componentDidMount() {
		getMovingItems().then(moving => {
			this.setState({ moving, lines: moving.map(item => {
				return { item: item.item, max: item.quantity };
			}) });
		}).catch(err => {
			this.setState({ error: err.message });
		});
	}

	updateLine(index, cb) {
		this.setState(state => {
			const copy = Object.assign({}, state);
			copy.lines = copy.lines.slice();
			copy.lines[index] = Object.assign({}, copy.lines[index]);
			cb(copy.lines[index]);
			return copy;
		});
	}

	changeLine(index, field) {
		return e => {
			const { value } = e.target;
			this.updateLine(index, line => {
				line[field] = value;
			});
		};
	}

	changeItem(index) {
		return e => {
			const item = this.state.moving.find(i => i.item === e.target.value);
			this.updateLine(index, line => {
				if(item) {
					line.item = item.item;
					line.max = item.quantity;
				} else {
					delete line.item;
				}
			});
		};
	}

	changeLocation(index, type) {
		const { stock } = this.props;
		return (e, value) => {
			const search = value.trim().toUpperCase();
			let matches = [];
			if(search) {
				const split = search.split(/\s+/);
				matches = stock.filter(({ _id }) =>
					split.every(term => _id.includes(term))).slice(0, MAX_RESULTS);
			}
			this.updateLine(index, line => {
				line[type] = value;
				line[`${type}Matches`] = matches;
			});
		};
	}

	selectLocation(index, type) {
		return (value, location) => {
			this.updateLine(index, line => {
				line[type] = location._id;
			});
		};
	}

	save(e) {
		e.preventDefault();
		const { stock } = this.props;
		const { lines } = this.state;
		const stockById = {};
		stock.forEach(s => {
			stockById[s._id] = s;
		});
		const toSave = { lines: [] };
		lines.forEach(line => {
			const to = stockById[line.to];
			const quantity = +line.quantity;
			if(line.item && to && quantity > 0) {
				toSave.lines.push({
					location: to._id,
					quantity,
					item: line.item
				});
			}
		});
		if(toSave.lines.length) {
			this.setState({ error: null });
			moveMultiple(toSave).then(() => {
				this.setState({ redirect: true });
			}).catch(err => {
				this.setState({ error: err.message });
			});
		}
	}

	render() {
		const { lines, error, redirect, moving } = this.state;
		if(redirect) {
			return <Redirect to="/" />;
		}
		const { stock } = this.props;
		const stockById = {};
		stock.forEach(s => {
			stockById[s._id] = s;
		});
		let hasLines = false;
		return <section className="container-fluid">
			<Table>
				<thead>
					<tr>
						<th>Item</th>
						<th>Naar</th>
						<th>Neergezet</th>
					</tr>
				</thead>
				<tbody>
					{lines.concat({}).map((line, index) => {
						if(stockById[line.to] && line.quantity > 0) {
							hasLines = true;
						}
						let className;
						if(line.quantity && Math.abs(line.quantity - line.max) >= MAX_DIFF) {
							className = 'bg-danger';
						}
						return <tr key={index} className={className}>
							<td>
								<select className="form-control" value={line.item || ''} onChange={this.changeItem(index)}>
									<option value=""></option>
									{moving.map(item => {
										return <option key={item.item} value={item.item}>{item.item}</option>;
									})}
								</select>
							</td>
							<td>
								<Autocomplete
									getItemValue={getId}
									onChange={this.changeLocation(index, 'to')}
									onSelect={this.selectLocation(index, 'to')}
									items={line.toMatches || []}
									value={line.to || ''} />
							</td>
							<td>
								<input type="number" className="form-control" value={line.quantity || ''}
									onChange={this.changeLine(index, 'quantity')} min={0} />
							</td>
						</tr>;
					})}
				</tbody>
			</Table>
			{error && <Alert color="danger">{error}</Alert>}
			<p><button className="btn btn-primary" disabled={!hasLines} onClick={this.save}>Save</button></p>
		</section>;
	}
}

export default connect(({ stock }) => {
	return { stock: stock.slice().sort(sortLocations) };
})(MoveMultiple);
