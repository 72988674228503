import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import PlanPage from '../order/PlanPage';
import PackingSlip from '../order/PackingSlip';
import PackingStickers from '../order/PackingStickers';
import EdiPage from '../edi/EdiPage';
import { getOrders, getLocations, getPallets, getTerms, getCod, getDelivery, getFreight, getPersons } from '../../api';
import { useWebSocket, hasLocalChanges } from '../../websocket';

const TIMEOUT = 900000;

function unloadListener(e) {
	if(hasLocalChanges()) {
		e.preventDefault();
		const out = 'Nog niet alle veranderingen zijn gesynchroniseerd';
		e.returnValue = out;
		return out;
	}
}

function updateOrders() {
	return getOrders(hasLocalChanges);
}

export default function PickApp({ person }) {
	const { role } = person;
	useWebSocket(updateOrders);
	useEffect(() => {
		getPersons();
		getPallets();
		if(role === 'TEAM_LEADER') {
			getLocations();
			getFreight();
		}
		if(['CUSTOMER_SERVICE', 'TEAM_LEADER'].includes(role)) {
			getTerms();
			getCod();
			getDelivery();
		}
		window.addEventListener('beforeunload', unloadListener);
		let timeout = 0;
		function sync() {
			timeout = setTimeout(() => {
				updateOrders().then(sync);
			}, TIMEOUT);
		}
		sync();
		return () => {
			clearTimeout(timeout);
			window.removeEventListener('beforeunload', unloadListener);
		};
	}, [role]);
	return <Switch>
		<Route path="/edi/:date" component={EdiPage} />
		<Route path="/order/:plan/stickers" component={PackingStickers} />
		<Route path="/order/:plan/slip" component={PackingSlip} />
		<Route path="/order/:plan" component={PlanPage} />
		<Route path="/:tab?/:sub?" component={HomePage} />
	</Switch>;
}
