const CBM = 1e6;
const ROUNDING = 100;
const PALLET = {
	width: 80,
	height: 200,
	length: 120
};
PALLET.cbcm = PALLET.width * PALLET.height * PALLET.length;

export function cbcmToCbm(size) {
	return Math.round(size / CBM * ROUNDING) / ROUNDING;
}

export function inPallets(size) {
	return Math.ceil(size / PALLET.cbcm);
}

export function getSize(line) {
	const { width, height, length, packagingUnit } = line.item;
	return width * height * length * line.quantity / packagingUnit;
}
