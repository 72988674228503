import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PickApp from './home/PickApp';
import ManagementApp from './management/ManagementApp';
import Status from './Status';
import { ROLES } from '../helper';
import { logout } from '../api';
import { hasLocalChanges } from '../websocket';

function LoggedIn({ person, history }) {
	function onClick(e) {
		e.preventDefault();
		if(hasLocalChanges() && !window.confirm('U heeft ongesynchroniseerde veranderingen, weet u zeker dat u wilt uitloggen?')) {
			return;
		}
		history.push('/');
		logout();
	}
	if(!person) {
		return null;
	}
	let app;
	if(person.role === 'MANAGER' || person.role === 'MOVE_ITEMS') {
		app = <ManagementApp person={person} />;
	} else {
		app =	<PickApp person={person} />;
	}
	return <div className={`role-${person.role}`}>
		<Status />
		<div className="login container">
			<div className="row">
				<div className="col-6">
					{person.name} <span className="role">{ROLES[person.role]}</span>
				</div>
				<div className="col-6 text-right">
					<button className="btn btn-sm btn-danger" onClick={onClick}>Logout</button>
				</div>
			</div>
		</div>
		{app}
	</div>;
}

export default withRouter(connect(({ person }) => {
	return { person };
})(LoggedIn));
