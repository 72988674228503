import React, { useState, useEffect, Fragment } from 'react';
import OrderPallets from './OrderPallets';
import { connect } from 'react-redux';
import { updateOrder } from '../../websocket';

function getColli(abc) {
	return abc.slice().sort((lhs, rhs) => {
		if(lhs._id < rhs._id) {
			return -1;
		} else if(lhs._id > rhs._id) {
			return 1;
		}
		return 0;
	}).reduce((all, o) => all.concat(o.pallets), []);
}

function samePallet(p1, p2) {
	if(p1 === p2) {
		return true;
	} else if(p1.pallet === p2.pallet) {
		if((p1.height || p2.height) && p1.height !== p2.height) {
			return false;
		}
		if((p1.weight || p2.weight) && p1.weight !== p2.weight) {
			return false;
		}
		return true;
	}
	return false;
}

function samePallets(c1, c2) {
	if(c1.length !== c2.length) {
		return false;
	}
	for(let i = 0; i < c1.length; i++) {
		if(!samePallet(c1[i], c2[i])) {
			return false;
		}
	}
	return true;
}

function PalletContainer({ pallets, readonly, abc, children, order }) {
	const [colli, setColli] = useState(() => getColli(abc));
	useEffect(() => {
		setColli(getColli(abc));
	}, abc);
	useEffect(() => {
		if(!samePallets(colli, getColli(abc))) {
			const { _id } = order;
			updateOrder(_id, 'pallets', colli);
			abc.forEach(o => {
				if(o._id !== _id) {
					updateOrder(o._id, 'pallets', []);
				}
			});
		}
	}, [colli]);
	function setPallet(type, quantity) {
		if(isNaN(quantity)) {
			return;
		}
		setColli(c => {
			let found = c.filter(p => p.pallet === type);
			if(found.length < quantity) {
				const out = c.slice();
				for(let i = 0; i < quantity - found.length; i++) {
					out.push({ pallet: type });
				}
				return out;
			}
			found = found.slice(quantity);
			return c.filter(p => !found.includes(p));
		});
	}
	const filtered = pallets.filter(p => p.dropship === order.dropship);
	const byPallet = {};
	colli.forEach((c, i) => {
		if(!byPallet[c.pallet]) {
			byPallet[c.pallet] = [];
		}
		byPallet[c.pallet].push({ colli: c, i });
	});
	return <Fragment>
		<OrderPallets pallets={filtered} readonly={readonly} abc={abc} colli={colli} setPallet={setPallet} setColli={setColli} dropship={order.dropship} byPallet={byPallet} />
		{children}
		<OrderPallets pallets={filtered} readonly={readonly} abc={abc} colli={colli} setPallet={setPallet} setColli={setColli} dropship={order.dropship} byPallet={byPallet} />
	</Fragment>;
}

const PalletWrapper = connect(({ pallets }) => {
	return { pallets };
})(PalletContainer);

export default PalletWrapper;
