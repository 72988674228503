import store, { BATCH_TYPE } from './store';

const WAIT = 250;
let timeout = -1;
let actions = [];

function run() {
	store.dispatch({
		type: BATCH_TYPE,
		payload: actions
	});
	actions = [];
	timeout = -1;
}

export default function dispatch(action) {
	actions.push(action);
	if(timeout < 0) {
		timeout = setTimeout(run, WAIT);
	}
}
