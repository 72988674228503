import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Reports from './Reports';
import { splitLocation, stringifyLocation, trimDate } from '../../helper';
import { exportStock, exportOnPallet, getMovingItems, MAX_MOVING } from '../../api';
import { moveItems } from '../../websocket';

const MAX_LOCATIONS = 50;

export function sortLocations(lhs, rhs) {
	if(lhs._id > rhs._id) {
		return 1;
	} else if(lhs._id < rhs._id) {
		return -1;
	}
	return 0;
}

class LocationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locations: this.getLocations(props),
			search: '',
			item: '',
			blocked: false,
			blockedItems: []
		};
	}

	getLocations(props) {
		return props.stock.slice().sort(sortLocations);
	}

	componentDidUpdate(props, state) {
		let locations;
		const sourceChanged = this.props.stock !== props.stock;
		if(sourceChanged) {
			this.setState({ blockedItems: [] });
			locations = this.getLocations(this.props);
		}
		if(this.state.search !== state.search || sourceChanged) {
			const search = this.state.search.trim().toUpperCase();
			if(search) {
				if(!locations) {
					locations = this.getLocations(this.props);
				}
				const split = search.split(/\s+/);
				locations = locations.filter(({ _id }) => split.every(term => _id.includes(term)));
			} else if(!locations) {
				locations = this.getLocations(this.props);
			}
		}
		if(this.state.item !== state.item || sourceChanged) {
			const search = this.state.item.trim();
			if(search) {
				if(!locations) {
					locations = this.getLocations(this.props);
				}
				locations = locations.filter(({ items }) => items.some(({ item, quantity }) => quantity !== 0 && item && item.startsWith(search)));
			} else if(!locations) {
				locations = this.getLocations(this.props);
			}
		}
		if(this.state.blocked !== state.blocked || sourceChanged) {
			if(!locations) {
				locations = this.getLocations(this.props);
			}
			if(this.state.blocked) {
				locations = locations.filter(l => l.blocked || l.items.some(({ blocked }) => blocked));
			}
		}
		if(locations) {
			this.setState({ locations });
		}
	}

	move(location, item) {
		return e => {
			e.preventDefault();
			if(item.quantity > 0 && !this.state.blockedItems.includes(item.item) && window.confirm(`Weet je zeker dat je ${item.item} wil verplaatsen?`)) {
				getMovingItems().then(moving => {
					if(moving.length >= MAX_MOVING) {
						return window.alert(`Het is niet mogelijk meer dan ${MAX_MOVING} items tegelijk te verplaatsen.`);
					}
					this.setState(state => {
						const blockedItems = state.blockedItems.slice();
						blockedItems.push(item.item);
						const clone = Object.assign({}, state);
						clone.blockedItems = blockedItems;
						return clone;
					});
					moveItems(location._id, {
						[item.item]: -item.quantity
					});
				}).catch(err => {
					window.alert(err.message);
				});
			}
		};
	}

	render() {
		const { stock, person } = this.props;
		const { locations, search, item, blocked } = this.state;
		const shown = locations.slice(0, MAX_LOCATIONS);
		const canMove = person.role === 'MOVE_ITEMS';
		const canEdit = !canMove && person.roles.includes('EDIT_LOCATIONS');
		return <section className="container">
			<div className="row">
				<div className="col-md-6">
					<p>
						{canMove && <Link className="btn btn-primary mb-1 mr-1" to="/stock/move">Verplaatsen</Link>}
						{!canMove && <Link className="btn btn-primary mb-1 mr-1" to="/stock/container">Container</Link>}
						{!canMove && <Link className="btn btn-primary mb-1 mr-1" to="/stock/containers">Planning</Link>}
						{canEdit && <Link className="btn btn-primary mb-1 mr-1" to="/moving">Verplaatsingen</Link>}
						{canEdit && <Link className="btn btn-primary mb-1 mr-1" to="/stock/break">Breuk</Link>}
						{canEdit && <Link className="btn btn-primary mb-1 mr-1" to="/stock/return">Retour</Link>}
						{canEdit && <Link className="btn btn-success mb-1 mr-1" to="/stock/new">Nieuw</Link>}
						{canEdit && <Link className="btn btn-primary mb-1 mr-1" to="/stock/items">Aandachtsitems</Link>}
					</p>
				</div>
				{!canMove && <div className="col-md-6 text-right">
					<UncontrolledButtonDropdown>
						<DropdownToggle color="primary" caret>Export (CSV)</DropdownToggle>
						<DropdownMenu>
							<DropdownItem className="btn" onClick={exportStock}>Voorraad</DropdownItem>
							<DropdownItem className="btn" onClick={exportOnPallet}>Op pallet</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
					{' '}
					<Reports date={trimDate(new Date())} />
				</div>}
			</div>
			<Row>
				<Col xs={6}>
					<input
						type="search"
						placeholder="Zoek op locatie"
						value={search}
						className="form-control"
						onChange={e => this.setState({
							search: e.target.value, item: ''
						})} />
				</Col>
				<Col xs={6}>
					<input
						type="search"
						placeholder="Zoek op itemnummer"
						value={item}
						className="form-control"
						onChange={e => this.setState({
							item: e.target.value, search: ''
						})} />
				</Col>
			</Row>
			<Row>
				<Col xs={6}>
					{shown.length} / {stock.length} worden weergegeven.
				</Col>
				<Col xs={6}>
					<label><input type="checkbox" checked={blocked} onChange={e => this.setState({ blocked: e.target.checked })} /> Toon alleen geblokkeerd</label>
				</Col>
			</Row>
			<Table>
				<thead>
					<tr>
						<th>Locatie</th>
						<th>Items</th>
						<th className="text-right">Hoeveelheid</th>
						<th className="text-center">Geblokkeerd</th>
						{canEdit && <th className="text-center">Bewerk</th>}
					</tr>
				</thead>
				<tbody>
					{shown.map(location => <tr key={location._id}>
						<td>
							<Link to={`/stock/${location._id}${item ? `?highlight=${encodeURIComponent(item)}` : ''}`}>
								{stringifyLocation(splitLocation(location._id))}
							</Link>
						</td>
						<td>{location.items.reduce((all, i) => {
							if(all.length) {
								all.push(', ');
							}
							if(i.quantity > 0 && canMove) {
								all.push(<Fragment key={i.item}>
									{i.item} ({i.quantity}) <button className="btn btn-sm btn-link" onClick={this.move(location, i)}>
										<i className="fas fa-people-carry"></i>
									</button>
								</Fragment>);
							} else {
								all.push(i.item);
							}
							return all;
						}, [])}</td>
						<td className="text-right">{location.items.reduce((total, { quantity }) => total + quantity, 0)}</td>
						<td className="text-center">{location.blocked ? 'Ja' : ''}</td>
						{canEdit && <td className="text-center"><Link to={`/stock/edit/${location._id}${item ? `?highlight=${encodeURIComponent(item)}` : ''}`}>
							<i className="fa fa-edit"></i>
						</Link></td>}
					</tr>)}
				</tbody>
			</Table>
		</section>;
	}
}

export default connect(({ stock, person }) => {
	return { stock, person };
})(LocationList);
