import { useEffect, useRef } from 'react';
import dispatch from './batch';
import { reset } from './api';
const RECONNECT_INTERVAL = 500;

class Socket {
	constructor() {
		this.open = false;
		this.queue = [];
		this.openListeners = [];
		['connect', 'onOpen', 'onError', 'onClose', 'onMessage'].forEach(key => {
			this[key] = this[key].bind(this);
		});
	}

	connect() {
		const { host, protocol } = window.location;
		const socket = new WebSocket(`${protocol.replace('http', 'ws')}//${host}/api/ws`);
		socket.addEventListener('open', this.onOpen);
		socket.addEventListener('error', this.onError);
		socket.addEventListener('close', this.onClose);
		socket.addEventListener('message', this.onMessage);
		this.socket = socket;
	}

	onOpen() {
		const queue = this.queue;
		this.queue = [];
		queue.forEach(msg => this.send(msg));
		dispatch({ type: 'setSocketStatus', connected: true });
		this.openListeners.forEach(r => r.current && r.current());
	}

	onError(...args) {
		console.warn('WS error:', args);
	}

	onClose() {
		this.socket = null;
		if(this.open) {
			window.setTimeout(this.connect, RECONNECT_INTERVAL);
		}
		dispatch({ type: 'setSocketStatus', connected: false });
	}

	onMessage(e) {
		try {
			const message = JSON.parse(e.data);
			if(['DeletePickOrder', 'UpdatePickOrderLine', 'NewPickOrder', 'UpdatePickOrder', 'UpdateMultipleOrders',
				'NewLocation', 'UpdateLocation', 'AddContainer', 'MoveItems', 'MassMutation', 'ElstPickedUp'].includes(message.type)) {
				dispatch(message);
			} else if(message.type === 'LoggedInMessage') {
				reset();
				window.alert('U bent op een andere locatie ingelogd');
			} else {
				console.warn('Received unknown message type', e);
			}
		} catch(err) {
			console.warn('Received non-json message', e);
		}
	}

	send(message) {
		const { socket, queue } = this;
		if(socket && socket.readyState === WebSocket.OPEN) {
			const listener = () => queue.push(message);
			socket.addEventListener('error', listener);
			try {
				socket.send(message);
			} catch(e) {
				console.warn('Failed to send message', e);
			}
			socket.removeEventListener('error', listener);
		} else {
			queue.push(message);
		}
	}
}

const connection = new Socket();

function send(message) {
	connection.send(JSON.stringify(message));
	dispatch(message);
}

function start(ref) {
	connection.openListeners.push(ref);
	connection.open = true;
	connection.connect();
}

function stop(ref) {
	connection.openListeners = connection.openListeners.filter(r => r !== ref);
	connection.open = false;
	if(connection.socket) {
		connection.socket.close();
	}
}

export function hasLocalChanges() {
	return connection.queue.length > 0;
}

export function discardLocalChanges() {
	connection.queue = [];
}

export function changeState(order, line, location, status) {
	send({
		type: 'UpdatePickOrderLine',
		payload: { order, line, status, location }
	});
}

export function setPicked(order, line, location, picked) {
	send({
		type: 'UpdatePickOrderLine',
		payload: { order, line, picked, location }
	});
}

export function setComments(order, line, location, comments) {
	send({
		type: 'UpdatePickOrderLine',
		payload: { order, line, comments, location }
	});
}

export function deleteOrder(order) {
	send({
		type: 'DeletePickOrder',
		payload: order
	});
}

function getUpdatePayload(_id, key, value) {
	const payload = {};
	if(typeof key !== 'object') {
		payload[key] = value;
	} else {
		Object.assign(payload, key);
	}
	payload._id = _id;
	return payload;
}

export function updateOrder(order, key, value) {
	send({
		type: 'UpdatePickOrder',
		payload: getUpdatePayload(order, key, value)
	});
}

export function updateOrders(orders, key, value) {
	send({
		type: 'UpdateMultipleOrders',
		payload: getUpdatePayload(orders, key, value)
	});
}

export function addLocation(id) {
	send({
		type: 'NewLocation',
		payload: id
	});
}

export function updateLocation(_id, items) {
	send({
		type: 'UpdateLocation',
		payload: {
			_id, items
		}
	});
}

export function blockLocation(_id, blocked) {
	send({
		type: 'UpdateLocation',
		payload: {
			_id, blocked
		}
	});
}

export function addContainer(container, locations, items) {
	send({
		type: 'AddContainer',
		payload: {
			container, locations, items
		}
	});
}

export function moveItems(location, items) {
	send({
		type: 'MoveItems',
		payload: {
			location, items
		}
	});
}

export function blockItems(_id, blockedItems) {
	send({
		type: 'UpdateLocation',
		payload: {
			_id, blockedItems
		}
	});
}

export function setPickedUpByElst(pickedUp) {
	send({
		type: 'ElstPickedUp',
		payload: pickedUp
	});
}

export function useWebSocket(onOpen) {
	const ref = useRef(onOpen);
	useEffect(() => {
		start(ref);
		return () => stop(ref);
	}, []);
}
