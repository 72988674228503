import React, { Fragment } from 'react';
import Barcode from 'react-barcode';
import PTMDAddress from '../PTMDAddress';
import '../../css/stickers.css';

export default function DachserStickers(props) {
	return <div id="stickers">
		{props.data.map(data => {
			const { address, dispatch, packages, relation, code } = data.order;
			const [date, time] = data.date.toISOString().split('T');
			return data.packages.map(pkg => <div key={pkg.id} className="sticker">
				<div className="box top">
					<div className="row">
						<div className="col-6">
							Versandeinheit
						</div>
						<h4 className="col-6 text-right">
							DACHSER
						</h4>
					</div>
					<div className="row">
						<h4 className="col-4">
							NVE / SSCC
						</h4>
						<div className="col-8">
							<Barcode value={pkg.id} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<div className="box left">
							Consignor / Absender:
							<PTMDAddress />
						</div>
						<div className="box left">
							Consignee / Empfänger:
							<p className="address">
								{address.map((line, i) => <Fragment key={i}>
									{line}<br />
								</Fragment>)}
							</p>
						</div>
					</div>
					<div className="col-6">
						<div className="box">
							<div className="row">
								<div className="col-6">
									Order-no.: / Auftrag:<br />
									{code}
								</div>
								<div className="col-6">
									Date / Datum:<br />
									{date}<wbr />T<wbr />{time}
								</div>
							</div>
							<p className="dispatch">{dispatch}</p>
							<p className="text-right">{pkg.number} / {packages}</p>
							<h2>{relation}</h2>
						</div>
					</div>
				</div>
			</div>);
		})}
	</div>;
}
