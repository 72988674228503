import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Input, Form } from 'reactstrap';
import { trimDate } from '../../helper';
import { createEdi, invoice, mailEdi } from '../../api';
import { compareOrders } from '../home/HomePage';

class EdiPage extends Component {
	constructor(props) {
		super(props);
		this.state = this.initState(props);
		this.state.submitting = false;
		this.state.data = null;
		this.state.invoiced = {};
		this.selectAll = this.selectAll.bind(this);
		this.submit = this.submit.bind(this);
		this.invoice = this.invoice.bind(this);
		this.mail = this.mail.bind(this);
	}

	invoice(e) {
		e.preventDefault();
		const { form, orders } = this.state;
		const invoices = Object.keys(form).filter(key => form[key]);
		invoice(invoices).then(data => {
			let message = '';
			Object.keys(data).forEach(key => {
				const order = orders.find(o => o._id === key);
				message += `\n${order ? order.code : key} : ${data[key]}`;
			});
			if(message) {
				window.alert(message);
			}
		}).catch(err => {
			let message = err.message;
			if(err.response && err.response.data) {
				message += `\n${err.response.data}`;
			}
			window.alert(message);
		});
	}

	mail(e) {
		e.preventDefault();
		const { form } = this.state;
		const invoices = Object.keys(form).filter(key => form[key]);
		this.setState({ submitting: true });
		mailEdi(invoices).then(() => {
			this.setState({ submitting: false });
			window.alert('Mail sent');
		}).catch(err => {
			window.alert(err.message);
			this.setState({ submitting: false });
		});
	}

	initState(props) {
		const { orders, match } = props;
		const minAge = moment().subtract(1, 'hour').toDate();
		const filtered = orders.filter(order => {
			const date = moment(order.dateOverride || order.shipmentDate);
			const key = trimDate(date);
			return key === match.params.date && order.status === 'SEALING'
				&& (!order.dropship || new Date(order.lastModified) < minAge);
		}).sort((lhs, rhs) => {
			if(lhs.dropship && !rhs.dropship) {
				return -1;
			} else if(rhs.dropship && !lhs.dropship) {
				return 1;
			} else if(lhs.freight > rhs.freight) {
				return 1;
			} else if(lhs.freight < rhs.freight) {
				return -1;
			}
			return compareOrders(lhs, rhs);
		});
		const form = {};
		filtered.forEach(order => {
			if(this.state && this.state.form[order._id]) {
				form[order._id] = this.state.form[order._id];
			} else {
				form[order._id] = false;
			}
		});
		return {
			orders: filtered,
			form
		};
	}

	componentDidUpdate(props) {
		if(!this.state.orders.length || this.props !== props && this.props.orders !== props.orders) {
			if(this.props.orders.length || this.state.orders.length) {
				const update = this.initState(this.props);
				if(this.state.orders.length || update.orders.length) {
					this.setState(update);
				}
			}
		}
	}

	toggleLocation(freight) {
		return e => {
			const { orders, form } = this.state;
			const update = Object.assign({}, form);
			orders.forEach(order => {
				if(e.target.checked !== update[order._id] && order.freight === freight
					&& (!e.target.checked || this.canSelect(order))) {
					update[order._id] = e.target.checked;
				}
			});
			this.setState({ form: update });
		};
	}

	selectAll(e) {
		const { orders, form } = this.state;
		const update = Object.assign({}, form);
		orders.forEach(order => {
			if(e.target.checked !== update[order._id] && (!e.target.checked || this.canSelect(order))) {
				update[order._id] = e.target.checked;
			}
		});
		this.setState({ form: update });
	}

	select(id) {
		return e => {
			const { form } = this.state;
			const update = Object.assign({}, form);
			update[id] = e.target.checked;
			this.setState({ form: update });
		};
	}

	submit(e) {
		e.preventDefault();
		const { form } = this.state;
		const invoices = Object.keys(form).filter(key => form[key]);
		this.setState({ submitting: true });
		createEdi(invoices).then(data => {
			const invoiced = Object.assign({}, this.state.invoiced);
			invoices.forEach(id => {
				invoiced[id] = true;
			});
			this.setState({ submitting: false, data, invoiced });
		}).catch(err => {
			window.alert(err.message);
			this.setState({ submitting: false });
		});
	}

	canGenerate(order) {
		return order.cod && order.delivery && order.term && order.pallets.length > 0;
	}

	canSelect(order) {
		return order.noStickers || order.dropship || order.cod && order.delivery && order.term;
	}

	render() {
		const { delivery, terms } = this.props;
		const { orders, form, data, submitting, invoiced } = this.state;
		if(!orders.length) {
			return <section className="container">
				<p>Er staan geen orders op Sealing</p>
			</section>;
		}
		let noneSelected = true;
		let allSelected = true;
		let canGenerate = false;
		let allInvoiced = true;
		return <section>
			<Form onSubmit={this.submit} className="container">
				<Table>
					<thead>
						<tr>
							<th><input type="checkbox" checked={allSelected} onChange={this.selectAll} /></th>
							<th>No.</th>
							<th>Vrachtlocatie</th>
							<th>Pallets</th>
							<th>Klant</th>
							<th>Cash on Delivery</th>
							<th>Delivery Option</th>
							<th>Incoterm</th>
						</tr>
					</thead>
					<tbody>
						{orders.map(order => {
							if(form[order._id]) {
								noneSelected = false;
								if(!canGenerate) {
									canGenerate = this.canGenerate(order);
								}
								if(allInvoiced && !order.dropship && !order.noStickers && !invoiced[order._id]) {
									allInvoiced = false;
								}
							} else if(this.canSelect(order)) {
								allSelected = false;
							}
							const disabled = !this.canSelect(order);
							return <tr key={order._id}>
								<td>
									<input type="checkbox" checked={form[order._id]} onChange={this.select(order._id)} disabled={disabled} />
								</td>
								<td>{order.code}</td>
								<td>
									{order.freight} {order.freight && !disabled ? <input
										type="checkbox"
										title="Selecteer locatie"
										onChange={this.toggleLocation(order.freight)}
										checked={form[order._id]} /> : null}
								</td>
								<td>{order.pallets.length}</td>
								<td>
									{order.customer.code} {order.customer.name}
								</td>
								<td>
									{order.cod}
								</td>
								<td>
									{order.delivery ? delivery.find(d => d.id === order.delivery).description : ''}
								</td>
								<td>
									{order.term ? terms.find(t => t.id === order.term).description : ''}
								</td>
							</tr>;
						})}
					</tbody>
				</Table>
				<input type="submit" className="btn btn-primary" value="Genereer" disabled={submitting || noneSelected || !canGenerate} />
				{' '}
				<input type="button" className="btn btn-primary" value="Mail to Dachser" disabled={submitting || noneSelected || !canGenerate} onClick={this.mail} />
				{' '}
				<input type="button" className="btn btn-primary" value="Factureer" disabled={noneSelected || !allInvoiced} onClick={this.invoice} />
			</Form>
			{data && <div className="container-fluid">
				<hr />
				<Input type="textarea" readOnly={true} value={data} wrap="off" rows={15} className="text-monospace" />
			</div>}
		</section>;
	}
}

export default connect(({ orders, delivery, terms }) => {
	return { orders, delivery, terms };
})(EdiPage);
